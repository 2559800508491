import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Top extends Component {
  static propTypes = {
    fetchTodaysLB: PropTypes.func.isRequired,
    fetchYesterdaysLB: PropTypes.func.isRequired,
    fetchAlltimeLB: PropTypes.func.isRequired,
    todaysLB: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    yesterdaysLB: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    alltimeLB: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    member: PropTypes.shape().isRequired
  }

  state = {
    error: null,
    success: null,
    loading: false
  }

  componentDidMount = () => this.fetchData();

  fetchData = (by, friends) => {
    by = by || "mc";
    friends = friends || false;
    const { fetchTodaysLB, fetchYesterdaysLB, fetchAlltimeLB } = this.props;

    this.setState({ loading: true });
    return this.handleAction(Promise.all([
      fetchTodaysLB({ count: 20, by, friends }),
      fetchYesterdaysLB({ count: 20, by, friends }),
      fetchAlltimeLB({ count: 20, by, friends })
    ]));
  }

  reftechData = (by, friends) => {
    this.fetchData(by, friends);
  }

  handleAction = (action) => {
    return action.then(() => this.setState({
      loading: false,
      error: null,
    })).catch(err => this.setState({
      loading: false,
      error: err.details ? err.details.code : err.code,
    }));
  }

  flushError = () => {
    this.setState({ error: null });
  }

  render = () => {
    const { Layout, todaysLB, yesterdaysLB, alltimeLB, member } = this.props;
    const { error, loading, success } = this.state;

    return (
      <Layout
        todaysLB={todaysLB}
        yesterdaysLB={yesterdaysLB}
        alltimeLB={alltimeLB}
        reftechData={this.reftechData}
        error={error}
        loading={loading}
        success={success}
        member={member}
      />
    );
  }
}

const mapStateToProps = state => ({
  todaysLB: state.leaderboards.todaysLB || [],
  yesterdaysLB: state.leaderboards.yesterdaysLB || [],
  alltimeLB: state.leaderboards.alltimeLB || [],
  member: state.member || {}
});

const mapDispatchToProps = dispatch => ({
  fetchTodaysLB: dispatch.leaderboards.getTodaysLB,
  fetchYesterdaysLB: dispatch.leaderboards.getYesterdaysLB,
  fetchAlltimeLB: dispatch.leaderboards.getAlltimeLB
});

export default connect(mapStateToProps, mapDispatchToProps)(Top);
