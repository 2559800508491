import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import emptyProfilePicImg from '../../../images/profile-placeholder.png';

class Top extends Component {
  static propTypes = {
    todaysLB: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    yesterdaysLB: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    alltimeLB: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    t: PropTypes.func.isRequired,
    reftechData: PropTypes.func.isRequired,
    error: PropTypes.string,
    success: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    member: PropTypes.shape().isRequired
  }

  state = {
    currFilteredBy: "mc",
    friends: false
  }

  static defaultProps = {
    member: {},
  }

  loadData = (by, friends) => {
    friends = friends === undefined ? this.state.friends : friends;
    const { reftechData } = this.props;
    this.setState({ currFilteredBy: by, friends });
    reftechData(by, friends);
  }

  switchFriends = (on) => {
    const { currFilteredBy } = this.state;
    this.loadData(currFilteredBy, on);
  }

  render() {

    const { t, todaysLB, yesterdaysLB, alltimeLB, loading, member } = this.props;
    const { currFilteredBy, friends } = this.state;

    return (
      <Row className="top-content">
        <Col xs="3" className="top-col">
          <Card>
            <CardBody className="top-buttons">
              <Row>
                <Col xs="6" className="top-buttons-foa-col-1"><Button disabled={loading || (!member || !member.uid)} onClick={() => this.switchFriends(true)} className={friends ? "active" : "not-active"}>{t('top.friendsTop')}</Button></Col>
                <Col xs="6" className="top-buttons-foa-col-2"><Button onClick={() => this.switchFriends(false)} disabled={loading} className={!friends ? "active" : "not-active"}>{t('top.allPlayers')}</Button></Col>
              </Row>
              <Row>
                <Col xs="12"><Button disabled={loading} onClick={() => this.loadData("mc")} className={currFilteredBy === "mc" ? "active" : "not-active"}>{t('top.masteryFactor')}</Button></Col>
              </Row>
              <Row>
                <Col xs="12"><Button disabled={loading} onClick={() => this.loadData("gWon")} className={currFilteredBy === "gWon" ? "active" : "not-active"}>{t('top.winCount')}</Button></Col>
              </Row>
              <Row>
                <Col xs="12"><Button disabled={loading} onClick={() => this.loadData("gLost")} className={currFilteredBy === "gLost" ? "active" : "not-active"}>{t('top.loseCount')}</Button></Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs="3" className="top-col">
          <Card className="top-20-card">
            <CardBody>
              <CardTitle>{t('top.todaysTop20')}</CardTitle>
              <ul>
                {todaysLB.map((val, i) => {
                  return <li key={`todays-top-20-${i}`}>
                    <span>{i + 1}.</span>
                    <img src={val.photo || emptyProfilePicImg} alt="" />
                    <span>{val.firstName}&nbsp;{val.lastName && val.lastName[0]}</span>
                    <span className="top-20-card-plus-wrap"><span className="top-20-card-plus">{val[currFilteredBy] > 0 ? ('+' + val[currFilteredBy]) : val[currFilteredBy]}</span></span>
                  </li>
                })}
              </ul>
            </CardBody>
          </Card>
        </Col>

        <Col xs="3" className="top-col">
          <Card className="top-20-card">
            <CardBody>
              <CardTitle>{t('top.yesterdaysTop20')}</CardTitle>
              <ul>
                {yesterdaysLB.map((val, i) => {
                  return <li key={`yesterdays-top-20-${i}`}>
                    <span>{i + 1}.</span>
                    <img src={val.photo || emptyProfilePicImg} alt="" />
                    <span>{val.firstName}&nbsp;{val.lastName && val.lastName[0]}</span>
                    <span className="top-20-card-plus-wrap"><span className="top-20-card-plus">{val[currFilteredBy] > 0 ? ('+' + val[currFilteredBy]) : val[currFilteredBy]}</span></span>
                  </li>
                })}
              </ul>
            </CardBody>
          </Card>
        </Col>

        <Col xs="3" className="top-col">
          <Card className="top-20-card top-20-card-alltime">
            <CardBody>
              <CardTitle>{t('top.alltimeTop20')}</CardTitle>
              <ul>
                {alltimeLB.map((val, i) => {
                  return <li key={`alltime-top-20-${i}`}>
                    <span>{i + 1}.</span>
                    <img src={val.photo || emptyProfilePicImg} alt="" />
                    <span>{val.firstName}&nbsp;{val.lastName && val.lastName[0]}</span>
                    <span className="top-20-card-plus-wrap"><span className="top-20-card-plus">{val[currFilteredBy] > 0 ? ('+' + val[currFilteredBy]) : val[currFilteredBy]}</span></span>
                  </li>
                })}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>

    );
  }
}


export default withTranslation('common')(Top);