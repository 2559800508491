import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Row, Col
} from 'reactstrap';
import classnames from 'classnames';

const HowToPlay = () => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <Row className="how-to-play-content">
      <Col xs="3" className="how-to-play-col">
        <Card>
          <CardBody>
            <Nav className="side-nav">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  Spēles nauda
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  RSP jeb Regulārā Spēlētāja Punkti
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}
                >
                  Neizšķirts
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggle('4'); }}
                >
                  Punkti un Meistarības koeficients
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => { toggle('5'); }}
                >
                  Meistaru klubs
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '6' })}
                  onClick={() => { toggle('6'); }}
                >
                  Kā sākt
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '7' })}
                  onClick={() => { toggle('7'); }}
                >
                  Aizliegumi
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '8' })}
                  onClick={() => { toggle('8'); }}
                >
                  Savienojuma zaudēšana
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '9' })}
                  onClick={() => { toggle('9'); }}
                >
                  Spēles principi
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '10' })}
                  onClick={() => { toggle('10'); }}
                >
                  Turnīri
                </NavLink>
              </NavItem>

            </Nav>
          </CardBody>
        </Card>
      </Col>
      <Col xs="9" className="how-to-play-col">
        <Card>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <h3>Spēles nauda</h3>
                <p>
                  Katrs spēlētājs saņem 23 monētas spēles uzsākšanai.<br /><br />
                  Spēlēt iespējams tik ilgi, kamēr spēlētāja virtuālajā kontā ir pietiekami daudz monētu. Ja monētas ir beigušās, tās iespējams papildus iegādāties sadaļā Papildināt. Virtuālo naudu iespējams tērēt iegādājoties „fīču” Gājienu palīgs un piedaloties zibensturnīros.<br /><br />
                  Virtuālā nauda jeb monētas ir paredzēta tikai tehniskai spēles nodrošināšanai, tā nevar nekādā veidā tikt izmaksāta, apmainīta vai aizstāta ar reālu naudu vai cita veida materiālajām vērtībām, vai balvām!
                </p>
              </TabPane>

              <TabPane tabId="2">
                <h3>RSP jeb Regulārā Spēlētāja Punkti</h3>
                <p>
                  Jo vairāk spēlēsi, jo vairāk RSP nopelnīsi! Tev ir 0.00 RSP! Šos punktus vari samainīt pret monētām!
                </p>
              </TabPane>

              <TabPane tabId="3">
                <h3>Neizšķirts</h3>
                <p>
                  Automātisks spēles neizšķirts tiek piešķirts abiem spēlētājiem, ja neviens kauliņš netiek nokauts 60 gājienu (30 savu un 30 pretinieka). Neizšķīrta gadījumā, katram spēlētājam tiek pa 0,2 RSP.
                </p>
              </TabPane>

              <TabPane tabId="4">
                <h3>Punkti un Meistarības koeficients</h3>
                <p>
                  Uzsākot spēli, katra meistarības koeficients ir 1000 punktu. Uzvarot vai zaudējot, tas attiecīgi pieaug vai sarūk..<br /><br />
                  Koeficients rēķinās pēc ELO sistēmas - ja tu esi spēcīgs spēlētājs un uzvari vāju spēlētāju, tu saņem mazāk punktus nekā tad, ja tu esi vājš un uzvari ļoti spēcīgu spēlētāju. Šeit piemērs, kā tas darbojas:.<br /><br />
                  ja tev ir 1000 punkti un pretiniekam ir 1000 punkti, tad uzvarot tev būs +8 un zaudējot -7 punkti;.<br />
                  ja tev ir 500 punkti un pretiniekam ir 1500 punkti, tad uzvarot tev būs +15 un zaudējot -0 punkti..<br /><br />
                  Spēlējot Apgriezto dambreti, arī tiek skaitīti punkti, taču tie būs piecas reizes mazāki nekā vari iegūt spēlējot klasisko dambreti..<br /><br />
                </p>
              </TabPane>

              <TabPane tabId="5">
                <h3>Meistaru klubs</h3>
                <p>
                  Uzsākot spēli, katram spēlētājam tiek iedots bezmaksas Meistara kluba abonements 3 dienām. Abonentam beidzoties, to iespējams atjaunot vai pagarināt spiežot Papildināt.<br /><br />
                  Meistara kluba biedri saņem īpašas spēles lietošanas privilēģijas: tikai viņi var piedalīties lielajos dambretes turnīros, redzēt savu statistiku un apbalvojumus, spēlēt Apgriezto dambreti, kā arī bezmaksas izmantot „fīču” Gājienu palīgs. Un tikai Meistaru kluba biedri var izvēlēties sev pretinieku, noraidot kādu, kas nav tīkams, bet pieteicies jau izveidotai spēlei.
                </p>
              </TabPane>

              <TabPane tabId="6">
                <h3>Kā sākt</h3>
                <p>
                  Tu vari pats izveidot spēli vai arī vari pieteikties jau izveidotai spēlei.<br /><br />
                  Pirmo gājienu izdara spēles izveidotājs.<br /><br />
                  Ja esi Meistaru kluba biedrs, Tu vari izveidot Apgriezto dambreti, vai arī tai pieteikties. Seko līdzi šim simbolam:<br /><br />
                  Spēlējot Apgriezto dambreti spēles Apbalvojumus nav iespējams saņemt.<br /><br />
                  Spēlēt Apgriezto dambreti vari tad, ja esi izspēlējis vismaz 10 parastās dambretes spēles.
                </p>
              </TabPane>

              <TabPane tabId="7">
                <h3>Aizliegumi</h3>
                <p>
                  Spēles lietotājiem aizliegts:<br /></p>
                  <ul>
                    <li>Izmantot draugiem.lv lietotāja profilu, kas izveidots vai tiek izmantots pretēji draugiem.lv profilu lietošanas noteikumiem;</li>
                    <li>Lietot necenzētus, godu un cieņu aizskarošus vārdus čata logā jebkurā valodā kā pret spēlētājiem, tā pret administrāciju;</li>
                    <li>Izteikt cilvēka cieņu, rasi, reliģisko piederību aizskarošas frāzes čatā;</li>
                    <li>Veikt jebkāda veida darbības, kas traucē pilnvērtīgu spēles izmantošanu citiem spēlētājiem;</li>
                  </ul>
                  <p>SPĒLES administrācija patur tiesības bloķēt spēlētāja piekļuvi spēlei bez iepriekšēja brīdinājuma uz nenoteiktu laiku pēc saviem ieskatiem. Spēlētāja bloķēšanas gadījumā spēlēs izmantotās monētu un meistaru kluba abonēšanas summas nekādā veidā netiek kompensētas.
                </p>
              </TabPane>

              <TabPane tabId="8">
                <h3>Savienojuma zaudēšana</h3>
                <p>
                  Diemžēl šāda problēma notiek dēļ zaudētām datu paketēm starp serveri un klientu, kas zaudētas dēļ nestabila vai īslaicīga savienojuma pārtrūkuma. Šādos gadījumos tiek noskaitīti punkti no tā, kuram pārtrūkst internets un šie punkti aiziet pretiniekam kā kompensācija par zaudēto laiku. Diemžēl savienojums var pārtrūkt jebkurā brīdī, arī, kad gājiens NAV pie Jums, tieši tad maldīgi šķiet, ka kādam citam ir noticis pārrāvums, lai gan īstenībā paša pusē ir bijusi problēmiņa.<br /><br />
                  Iesakām Jums:<br /></p>

                  <ul>
                    <li>nelietot citas interneta resursus patērējošas programmas, kamēr spēlējat Dambreti;</li>
                    <li>lejupielādēt jaunāko sava interneta pārlūka versiju;</li>
                    <li>iespēju robežās nespēlēt caur bezvadu internetu;</li>
                    <li>iztīrīt datoru no nevajadzīgām programmām/failiem;</li>
                    <li>neizmantot Antivīrusa skanēšanu, kamēr spēlējat Dambreti;</li>
                  </ul>
                  <p>
                  Mēs, Dambretes administrācija, par spēlētāja pieslēguma kvalitāti neatbildām un spēles monētas par šādām problēmām nekompensējam.
                </p>
              </TabPane>

              <TabPane tabId="9">
                <h3>Spēles principi</h3>
                <p>
                  <i>Spēles mērķis</i><br />
                  Paņemt visus pretinieka kauliņus vai bloķēt visus iespējamos pretinieka gājienus.<br /><br />

                  <i>Galdiņš</i><br />
                  Dambreti spēlē uz galdiņa ar 64 lauciņiem. Viens spēlētājs spēlē ar tumšiem, bet otrs - ar gaišiem kauliņiem. Kauliņus drīkst pārvietot tikai pa tumšajiem lauciņiem.<br /><br />

                  <i>Kauliņu pārvietošana</i><br />
                  Spēli sāk tas, kas pirmais izveidojis to un pēc tam spēlētāji izdara gājienus pēc kārtas. Kad ir tava kārta, noklikšķini uz kauliņa un pēc tam uz lauciņa, uz kuru tas jāpārvieto. Gājieni jāizdara uz brīvu lauciņu, vai nu pārvietojot kauliņu par vienu lauciņu pa diagonāli, vai ceļot to pāri pretinieka kauliņam.<br /><br />

                  <i>Kauliņa gājieni</i><br />
                  Dambretes kauliņi dalās vienkāršos kauliņos un dāmās. Sākuma stāvoklī visi kauliņi ir vienkāršie. Kauliņš iet uz priekšu pa diagonāli uz blakusesošo brīvo lauciņu. Ja spēles gaitā kauliņš sasniedz vienu no pretējās puses pēdējās - astotās horizontāles lauciņiem, tad tas pārvēršas dāmā, iegūstot jaunas tiesības. Katrai pusei vienlaikus uz galdiņa drīkst būt vairākas dāmas. Dāmai atšķirībā no kauliņa ir tiesības pārvietoties pa diagonāli vairākus lauciņus jebkurā virzienā (kā uz priekšu, tā arī atpakaļ), bet apstāties, tāpat kā vienkāršie kauliņi, tā drīkst tikai uz lauciņa, uz kura neatrodas neviens cits kauliņš, pie tam pārlēkt pāri savam kauliņam tā nedrīkst.<br /><br />

                  <i>Sišana ar kauliņu</i><br />
                  Izdarot gājienu, kauliņam obligāti jāsit pretinieka kauliņš, ja tas atrodas uz blakus (pa diagonāli) lauciņa un lauciņš aiz pretinieka kauliņa ir brīvs. Šajā gadījumā kauliņš, kurš izdara sitienu, nostājas uz šī brīvā lauciņa (pārlecot pār pretinieka kauliņu). Kauliņš sit kā uz priekšu, tā arī atpakaļ. Ar vienu paņēmienu kauliņam jānosit tik daudz pretinieka kauliņu, cik to atrodas sitēja kauliņa ceļā (ja pastāv sitienam nepieciešamie apstākļi). Ar vienu paņēmienu vairāku pretinieka kauliņu sišanu vērtē kā vienu gājienu. Ja iespējams sist divos vai vairākos virzienos, tad neatkarīgi no sitamo kauliņu daudzuma un kvalitātes (dāmas, vienkāršie kauliņi) turpinājumu izvēlas sitējs. Dāmai jāsit pretinieka kauliņš (kā uz priekšu, tā arī atpakaļ) neatkarīgi no attāluma līdz tam, ja tikai kauliņš ar dāmu atrodas uz vienas diagonāles un ja aiz kauliņa ir brīvs lauciņš. Ja aiz pretinieka kauliņa ir vairāki brīvi lauciņi no vietas, tad dāma pēc sitiena var apstāties uz jebkura no brīvajiem lauciņiem pēc savas izvēles. Ja, izdarot sitienu ar dāmu, uz kādas no krustojošām diagonālēm vēl atrodas pretinieka kauliņi, aiz kuriem ir brīvi lauciņi, tad dāmai ir jāturpina šo kauliņu sišana neatkarīgi no tā, cik šo kauliņu ir. Ja ir iespējami vairāki sitienu veidi, sitējam ir tiesības izvēlēties jebkuras krustojošās diagonāles. Ja vienkāršais kauliņš, sitot pretinieka kauliņu, sasniedz, no savas puses skaitot, pēdējo - astoto horizontālo rindu un ja tam ir iespēja turpināt sišanu, tad obligāti ar to pašu gājienu sišana jāturpina, bet jau ar dāmas tiesībām. Ja turpretim vienkāršais kauliņš pēdējo horizontālo rindu sasniedz nesitot un iespēja sist pretinieka kauliņu rodas pēc tam, tad sitiens jāizpilda (ja saglabājas iespēja sist) tikai nākošā gājienā jau ar dāmas tiesībām.<br /><br />

                  <i>Spēles beigas</i><br />
                  Spēle var beigties divējādi:<br />
                  </p>
                  <ul>
                    <li>Panākot uzvaru, kurā pretinieks vairs nevar izdarīt nevienu, noteikumiem atbilstošu gājienu, t.i. iznīcinot vai iesprostojot visus pretinieka kauliņus un/vai dāmas.</li>
                    <li>Vai vari piedāvāt neizšķirtu, noklikšķinot uz pogas Neizšķirts. Neizšķirtā spēlē neviens no spēlētājiem nav uzvarētājs. Ja pretinieks apstiprina neizšķirtu, tiek parādīts ziņojums "Neizšķirts", un spēle tiek izbeigta. Ja pretinieks noraida neizšķirtu, spēle tiek turpināta. Uzmanību, neizšķirtu var piedāvāt katrs no spēlētājiem tikai piecas reizes!</li>
                  </ul>
                  <p>
                  Nospiežot pogu Beigt spēli, tam, kas izvēlas spēli beigt, tiek piešķirts zaudējums
                </p>
              </TabPane>

              <TabPane tabId="10">
                <h3>Turnīri</h3>
                <p>
                  Lielajos turnīros var piedalīties tikai Meistaru kluba biedri. Lielais zibensturnīrs norisinās katru dienu plkst 20:00. Turnīram ir noteikta dalības maksa, izteikta monētās.<br /><br />
                  Turnīra uzvarētājs saņem 50% no visu turnīra dalības maksu summas.<br />
                  Piemērs. Turnīram piesakās 10 spēlētāji * 2 monētas = 10 monētas turnīra uzvarētājam.<br /><br />
                  Turnīram pieteikties, vēlams pirms paša turnīra sākuma, jo piesakoties ātrāk un uzsākot citu - regulāro spēli vai izejot no portāla, pieteikums tiks automātiski atcelts. Pametot sākušos turnīru, tā dalības maksa netiek atgriezta un ir liegts spēlēt dambreti kamēr turnīrs nav beidzies.<br /><br />
                  Turnīra princips ir katram spēlētājam izspēlēt dambreti ar katru turnīra dalībnieku.<br /><br />
                  Par katru uzvaru turnīrā tiek piešķirti 4 punkti, 2 punkti – par neizšķirtu un 1 punkts par zaudējumu. Gadījumā, ja kāds dalībnieks turnīru pamet, visiem pārējiem dalībniekiem neatkarīgi no spēļu rezultāta ar šo dalībnieku, tiek piešķirti 4 punkti.<br /><br />
                  Ja turnīra beigās vairākiem spēlētājiem ir vienāds punktu skaits, tad uzvarētājs ir tas, kuram ir augstāks Meistarības koeficients.<br /><br />
                  No 23:00 - 18:00 notiek mazie turnīri, kas sākas tiklīdz pieteikušies 6 spēlētāji. Mazajos turnīros var piedalīties arī dalībnieki, kas nav Meistara kluba biedri. Mazā turnīra uzvarētājam netiek piešķirts apbalvojums – kauss. Apbalvojums tiek piešķirts tikai Lielā turnīra uzvarētājam.
                </p>
              </TabPane>

            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>);
}

HowToPlay.propTypes = {

};

HowToPlay.defaultProps = {

};

export default HowToPlay;