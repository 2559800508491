import { Firebase, FirebaseRef } from '../lib/firebase';

// TODO: rename stats to status
export default {
  /**
   *  Initial state
   */
  state: {
    playersOnline: 0,
    playerInGame: 0
  },

  /**
   * Reducers
   */
  reducers: {
    reducePlayersOnline(state, playersOnline) {
      return { ...state, playersOnline };
    },
    reducePlayerInGame(state, playerInGame) {
      return { ...state, playerInGame };
    }
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    getStatus() {
      if (Firebase === null) return new Promise(resolve => resolve);

      return new Promise((resolve) => {
        FirebaseRef.child(`status`).on('value', (statsSS) => {
          this.reducePlayersOnline(statsSS.numChildren())
        })
        FirebaseRef.child(`gameStates`).orderByChild("closed").equalTo(false).on('value', (gamesStateSS) => {
          this.reducePlayerInGame(gamesStateSS.numChildren())
        });

        resolve();
      });
    }
  })
};
