import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Navbar,
  Collapse,
  NavbarToggler,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';

import headerBgImg from '../../../images/header.png'
import gameMoneyImg from '../../../images/icons/game-money.svg';

//import config from '../../../constants/config';


class Header extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    member: PropTypes.shape().isRequired,
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.state = { isOpen: false };
  }

  onLogout = () => {
    const { logout, history } = this.props;
    logout().then(() => history.push('/'));
  }

  toggleDropDown = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  render() {
    const { t, member } = this.props;
    const { isOpen } = this.state;

    const loggedIn = !!(member && member.uid);

    return (

      
      <header>
        <img className="header-bg" src={headerBgImg} alt="header-background" />
        <Navbar className="navbar navbar-expand-md navbar-dark">
          <NavbarToggler onClick={this.toggleDropDown} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <li className={`nav-item ${this.props.location.pathname === '/' ? 'active': ''}`}>
                <Link className="nav-link" to="/">{t('header.home')} <span className="sr-only">(current)</span></Link>
              </li>
              <li className={`nav-item ${this.props.location.pathname === '/how-to-play' ? 'active': ''}`}>
                <Link className="nav-link" to="/how-to-play">{t('header.howToPlay')}? <span className="sr-only">(current)</span></Link>
              </li>
              <li className={`nav-item ${this.props.location.pathname === '/awards' ? 'active': ''}`}>
                <Link className="nav-link" to="/awards">{t('header.awards')} <span className="sr-only">(current)</span></Link>
              </li>
              <li className={`nav-item ${this.props.location.pathname === '/top' ? 'active': ''}`}>
                <Link className="nav-link" to="/top">{t('header.top')} <span className="sr-only">(current)</span></Link>
              </li>
              {loggedIn && (
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  {t('menu.inAccount')}: <b>{member.bal}</b>
                  <img className="my-money-ico" src={gameMoneyImg} alt="Money" />
                </Link>
              </li>)}
              {/*(config.DEV && loggedIn) && (
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={this.onLogout}>[Logout]</a>
              </li>)*/}
            </Nav>
          </Collapse>
        </Navbar>
      </header>

    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = dispatch => ({
  logout: dispatch.member.logout
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('common'),
  withRouter
)(Header);