import { Firebase, FirebaseRef } from '../lib/firebase';

export default {
  /**
   *  Initial state
   */
  state: {
    awards: {}
  },

  /**
   * Reducers
   */
  reducers: {
    replaceAwards(state, payload) {
      let awards = {};

      if (payload && typeof payload === 'object') {
        awards = payload;
      }

      return { ...state, awards };
    }
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    getAwards() {
      if (Firebase === null) return new Promise(resolve => resolve);

      return new Promise(async (resolve) => {
        const unsubscribe = Firebase.auth().onAuthStateChanged((loggedIn) => {
          unsubscribe();
          if (loggedIn) {
            return FirebaseRef.child(`awards/${loggedIn.uid}`).on('value', (snapshot) => {
              const awards = snapshot.val() || {};
              this.replaceAwards(awards);
              return resolve();
            });
          }
          return new Promise(() => resolve);
        });
      });
    }

  })
};
