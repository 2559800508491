import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

class Menu extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    createTable: PropTypes.func.isRequired,
    joinTable: PropTypes.func.isRequired,
    closeTable: PropTypes.func.isRequired,
    leaveTable: PropTypes.func.isRequired,
    fetchTables: PropTypes.func.isRequired,
    fetchGameState: PropTypes.func.isRequired,
    fetchMember: PropTypes.func.isRequired,
    resetGameState: PropTypes.func.isRequired,
    fetchTodaysLB: PropTypes.func.isRequired,
    tables: PropTypes.shape().isRequired,
    member: PropTypes.shape().isRequired,
    gameState: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    todaysLB: PropTypes.arrayOf(PropTypes.shape()).isRequired
  }

  state = {
    error: null,
    success: null,
    loading: false,
    dayBonus: null
  }

  componentDidMount = () => {
    this.fetchData();
  }

  componentDidUpdate = () => {
    this.checkInGame();
  }

  fetchData = () => {
    const { fetchMember, fetchTables, fetchGameState, checkDayBonus, fetchTodaysLB } = this.props;

    this.setState({ loading: true });

    return this.handleAction(Promise.all([
      fetchMember(),
      fetchGameState(),
      fetchTables(),
      fetchTodaysLB(),
      checkDayBonus().then((res) => {
        this.setState({ dayBonus: res.data })
        return Promise.success();
      }),
    ]));
  }

  checkInGame = () => {
    const { gameState, history } = this.props;

    if (gameState && gameState.closed === false && gameState.joinedTable) {
      history.push('/game/' + gameState.joinedTable);
      return true;
    }

    return false;
  };

  handleAction = (action) => {
    return action.then(() => {
      this.setState({
        loading: false,
        error: null,
      });
    }).catch(err => {
      this.setState({
        loading: false,
        error: err.details ? err.details.code : err.code,
      });
    });
  }

  createTable = (levelChosen) => {
    const { createTable } = this.props;

    this.setState({ loading: true });

    return this.handleAction(createTable(levelChosen));
  }

  joinTable = (tabledId) => {
    const { joinTable } = this.props;

    this.setState({ loading: true });

    return this.handleAction(joinTable(tabledId));
  }

  closeTable = () => {
    const { closeTable } = this.props;

    this.setState({ loading: true });

    return this.handleAction(closeTable());
  }

  leaveTable = () => {
    const { leaveTable } = this.props;

    this.setState({ loading: true });

    return this.handleAction(leaveTable());
  }

  flushError = () => {
    this.setState({ error: null });
  }

  render = () => {
    const { member, Layout, tables, gameState, todaysLB } = this.props;
    const { error, loading, success, dayBonus } = this.state;

    return (
      <Layout
        error={error}
        member={member}
        loading={loading}
        success={success}
        createTable={this.createTable}
        joinTable={this.joinTable}
        closeTable={this.closeTable}
        flushError={this.flushError}
        leaveTable={this.leaveTable}
        dayBonus={dayBonus}
        gameState={gameState}
        tables={tables}
        todaysLB={todaysLB}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  tables: state.table.tables || {},
  gameState: state.game.gameState || {},
  todaysLB: state.leaderboards.todaysLB || [],
});

const mapDispatchToProps = dispatch => ({
  createTable: dispatch.table.createTable,
  closeTable: dispatch.table.closeTable,
  joinTable: dispatch.table.joinTable,
  leaveTable: dispatch.table.leaveTable,
  fetchMember: dispatch.member.getMemberData,
  fetchTables: dispatch.table.getTables,
  fetchGameState: dispatch.game.getGameState,
  resetGameState: dispatch.game.resetGameState,
  checkDayBonus: dispatch.member.checkDayBonus,
  fetchTodaysLB: dispatch.leaderboards.getTodaysLB
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Menu);
