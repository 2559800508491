import { Firebase, FirebaseRef } from '../lib/firebase';

export default {
  /**
   *  Initial state
   */
  state: {
    tables: {},
    table: {}
  },

  /**
   * Reducers
   */
  reducers: {
    replaceTables(state, payload) {
      let tables = {};
      if (payload && typeof payload === 'object') {
        tables = payload;
      }

      return { ...state, tables };
    },
    replaceTable(state, payload) {
      let table = {};
      if (payload && typeof payload === 'object') {
        table = payload;
      }

      return { ...state, table };
    }
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    createTable(levelChosen) {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const createTableFunction = Firebase.app().functions().httpsCallable('createTable');

      return new Promise((resolve, reject) => createTableFunction({ levelChosen }).then(() => {
        return resolve();
      }).catch(reject)).catch((err) => { throw err; });
    },

    joinTable(tableId) {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const joinTableFunction = Firebase.app().functions().httpsCallable('joinTable');

      return new Promise((resolve, reject) => joinTableFunction({ tableId }).then(() => {
        return resolve();
      }).catch(reject)).catch((err) => { throw err; });
    },

    leaveTable() {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const leaveTableFunction = Firebase.app().functions().httpsCallable('leaveTable');

      return new Promise((resolve, reject) => leaveTableFunction().then(() => {
        return resolve();
      }).catch(reject)).catch((err) => { throw err; });
    },

    getTables() {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise(resolve => FirebaseRef.child('tables')
        .on('value', (snapshot) => {
          const data = snapshot.val() || {};
          this.replaceTables(data);
          return resolve();
        })).catch((err) => { throw err.message; });
    },

    getTable(tableId) {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise(resolve => FirebaseRef.child(`tables/${tableId}`)
        .once('value', (snapshot) => {
          const data = snapshot.val() || {};
          this.replaceTable(data);
          return resolve();
        })).catch((err) => { throw err.message; });
    },

    closeTable() {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const closeTableFunction = Firebase.app().functions().httpsCallable('closeTable');

      return new Promise((resolve, reject) => closeTableFunction().then(() => {
        return resolve();
      }).catch(reject)).catch((err) => { throw err; });
    }
  })
};
