import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import winIconImg from '../../../../images/icons/wins.svg';
import lossesIconImg from '../../../../images/icons/losses.svg';
import pointsIconImg from '../../../../images/icons/points.svg';
import drawsIconImg from '../../../../images/icons/draws.svg';

import emptyProfilePicImg from '../../../../images/profile-placeholder.png';
import Badge from '../../UI/Badge'

const PlayerProfile = ({ playerData, t, compact }) => (

  <div>
    <Row className="profile-box-row profile-box-row-basics">
      <Col xs="4" sm="4" md="4">
        <div className="profile-box-foto">
          <img alt="Profile" src={playerData.photo || emptyProfilePicImg} />
          <Badge mc={playerData.mc} />
        </div>
      </Col>
      <Col xs="8" sm="8" md="8" className={`profile-box-name ${compact ? 'profile-box-name-compact' : ''}`}><span>{`${playerData.firstName} ${playerData.lastName}`}</span></Col>
    </Row>
    <Row className="profile-box-row profile-box-row-stats">
      <Col xs="4" sm="4" md="4"><img id="ico-wins" src={winIconImg} alt="Wins" /><span>{playerData.gWon || 0}</span></Col>

      <UncontrolledTooltip placement="top" target="ico-wins">
        {t('wins')}
      </UncontrolledTooltip>

      <Col xs="4" sm="4" md="4"><img id="ico-losses" src={lossesIconImg} alt="Losses" /><span>{playerData.gLost || 0}</span></Col>

      <UncontrolledTooltip placement="top" target="ico-losses">
        {t('losses')}
      </UncontrolledTooltip>

      {!compact && (
        <Fragment>
          <Col xs="4" sm="4" md="4"><span id="rsp-stat">{t('RPP')}: {playerData.rsp || 0}</span></Col>

          <UncontrolledTooltip placement="top" target="rsp-stat">
            {t('regularPlayerPoints')}
          </UncontrolledTooltip>
        </Fragment>
      )}
    </Row>
    <Row className="profile-box-row profile-box-row-stats">
      <Col xs="4" sm="4" md="4"><img id="ico-draws" src={drawsIconImg} alt="Draws" /><span>{playerData.gDraw || 0}</span></Col>

      <UncontrolledTooltip placement="top" target="ico-draws">
        {t('draws')}
      </UncontrolledTooltip>

      <Col xs="4" sm="4" md="4"><img id="ico-mc" src={pointsIconImg} alt="Mastery coefficient" /><span>{playerData.mc || 0}</span></Col>

      <UncontrolledTooltip placement="top" target="ico-mc">
        {t('masteryCoefficient')}
      </UncontrolledTooltip>
    </Row>

    {compact && (
      <Row className="text-left">
        <Col xs="12" sm="12" md="12"><span id="rsp-stat">{t('RPP')}: {playerData.rsp || 0}</span></Col>

        <UncontrolledTooltip placement="top" target="rsp-stat">
          {t('regularPlayerPoints')}
        </UncontrolledTooltip>
      </Row>
    )}
  </div>
);

PlayerProfile.propTypes = {
  playerData: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
  compact: PropTypes.bool.isRequired
}

PlayerProfile.defaultProps = {
  playerData: {},
  compact: true
};

export default PlayerProfile;
