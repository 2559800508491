import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Firebase, FirebaseRef } from '../../lib/firebase';
import config from '../../constants/config';

class Auth extends React.Component {
  static propTypes = {
    checkLogin: PropTypes.func.isRequired,
    //getOffset: PropTypes.func.isRequired,
    member: PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    signOut: PropTypes.func.isRequired,
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.checkLoginState = this.checkLoginState.bind(this);
  }

  updateUserLastLogin = () => {
    if (Firebase === null) return;

    const unsubscribe = Firebase.auth().onAuthStateChanged((loggedIn) => {
      unsubscribe();
      if (loggedIn) {
        FirebaseRef.child(`status/${loggedIn.uid}`).onDisconnect().remove();
        FirebaseRef.child(`status/${loggedIn.uid}`).update({
          lastAction: Firebase.database.ServerValue.TIMESTAMP,
        }).catch((e) => {
          console.error(e);
        });
      }
    });
  }

  componentDidMount() {
    const { fetchUserData, draugiemAuth } = this.props;
    const { hostname, search/*, pathname*/ } = window.location;

    /*if (!config.isInAppFrame()) {
      const unsb = Firebase.auth().onAuthStateChanged(user => {
        unsb();
        if (!user && !pathname.includes('dra-redirect')) {
          this.props.history.push('/landing');
        }else if(user && !pathname.includes('admin') && !pathname.includes('game/')){
          this.props.history.push('/');
        }
      });
      return;
    }*/

    if (config.isDraApp()) {

      let authCodeText;

      try {
        const urlParams = new URLSearchParams(search);
        authCodeText = urlParams.get('dr_auth_code');
      } catch (err) {
        authCodeText = this.getParameterByName('dr_auth_code');
      }

      if (authCodeText && hostname.includes('dra')) {
        draugiemAuth(authCodeText).then(() => {
          this.updateUserLastLogin();
          fetchUserData();
        });
      } else if (hostname.includes('dra')) {
        setTimeout(() => {
          let authCodeText2;
          const search2 = window.location.search;

          try {
            const urlParams = new URLSearchParams(search2);
            authCodeText2 = urlParams.get('dr_auth_code');
          } catch (err) {
            authCodeText2 = this.getParameterByName('dr_auth_code');
          }

          if (authCodeText2) {
            draugiemAuth(authCodeText2).then(() => {
              this.updateUserLastLogin();
              fetchUserData();
            });
          }
        }, 1500);
      }
    } else if (config.isFBApp()) {
      console.log('is fb app');
      document.addEventListener('FBObjectReady', this.initializeFacebookLogin);
    }

  }

  componentWillUnmount() {
    const { signOut } = this.props;
    signOut();
  }

  getParameterByName = (name, url) => {
    let url2;
    if (!url) {
      url2 = window.location.href;
    } else {
      url2 = url;
    }

    const name2 = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name2}(=([^&#]*)|&|#|$)`);

    const results = regex.exec(url2);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  initializeFacebookLogin = (e) => {
    this.checkLoginState(e.FBResponse);
  }


  checkLoginState(FBResponse) {
    console.log('checkLoginState');
    console.log(FBResponse);
    
    const { checkLogin, signOut } = this.props;

    if (FBResponse.status === 'connected') {
      checkLogin(FBResponse).then(() => {
        this.updateUserLastLogin();
      });
    } else {
      signOut().then(() => {
        window.FB.login((subEvent) => {
          checkLogin(subEvent).then(() => {
            this.updateUserLastLogin();
          });
        }, { scope: 'email' });
      });
    }
  }

  render() {
    return (
      null
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = dispatch => ({
  checkLogin: dispatch.member.checkLoginState,
  getOffset: dispatch.member.getTimeOffset,
  signOut: dispatch.member.logout,
  fetchUserData: dispatch.member.getMemberData,
  draugiemAuth: dispatch.member.draugiemAuth,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
