import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'progressbar.js';

class Timer extends Component {
  static propTypes = {
    onTimeout: PropTypes.func.isRequired,
    timeout: PropTypes.number
  }

  state = {
    timeout: null
  }

  constructor(props) {
    super(props);
    this.interval = null;
    this.timerTextEl = null;
    this.bar = null;
  }

  componentDidMount() {
    const { timeout } = this.props;

    this.timerTextEl = document.getElementById("timerText");
    this.bar = new ProgressBar.Path('#timerAnimPart', {});

    if (timeout) {
      this.animateTimer();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.timeout && prevProps.timeout !== this.props.timeout) {
      this.animateTimer();
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.interval);
  }

  _animationStep = () => {
    const { timeout, onTimeout } = this.props;

    const milis = timeout - new Date().getTime();

    if (!timeout || milis <= 0) {
      if (!timeout) {
        this.timerTextEl.innerHTML = "";
      } else {
        this.timerTextEl.innerHTML = "0";
      }
      window.cancelAnimationFrame(this.interval);
      this.interval = null;
      this.bar.set(-0);
      if (timeout) {
        onTimeout();
      }
      return;
    }

    const seconds = milis / 1000;
    let min = seconds / 60;
    const sec = min % 1;

    min = Math.floor(min);

    this.timerTextEl.innerHTML = min + 1;

    this.bar.set(-sec);

    this.interval = window.requestAnimationFrame(this._animationStep);
  }

  animateTimer = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.interval = window.requestAnimationFrame(this._animationStep);
  }


  render() {
    return (
      <Fragment>
        <div className="game-timer" dangerouslySetInnerHTML={{
          __html: `
        <span id="timerText"></span>
        <svg
          style="display: flex; flex-shrink: 1;"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 21 29"
          height="108"
          width="81">
          <path
            d="M 1.4261282,0.85298851 H 19.844357 c 0.317519,0 0.57314,0.25562029 0.57314,0.57313969 V 27.438457 c 0,0.31752 -0.255621,0.57314 -0.57314,0.57314 H 1.4261282 c -0.3175194,0 -0.57313969,-0.25562 -0.57313969,-0.57314 V 1.4261282 c 0,-0.3175194 0.25562029,-0.57313969 0.57313969,-0.57313969 z"
            style="fill:none;stroke:#ffe1cc;stroke-width:0.568663;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
          <path
            id="timerAnimPart"
            style="fill:none;stroke:#ffe1cc;stroke-width:1.70598;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 10.635242,0.85298851 h 9.209115 c 0.317519,0 0.57314,0.25562029 0.57314,0.57313969 V 27.438457 c 0,0.31752 -0.255621,0.57314 -0.57314,0.57314 H 1.4261282 c -0.3175194,0 -0.57313969,-0.25562 -0.57313969,-0.57314 V 1.4261282 c 0,-0.3175194 0.25562029,-0.57313969 0.57313969,-0.57313969 h 9.2091138" />
        </svg>`}} />
      </Fragment>
    );
  }
}

export default Timer;