import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import lvTranslations from '../constants/translations/lv';
import ruTranslations from '../constants/translations/ru';
import enTranslations from '../constants/translations/en';

let lng = 'lv';
try {
  if (localStorage && localStorage.getItem('language') && localStorage.getItem('language') !== null && localStorage.getItem('language') !== undefined) {
    lng = localStorage.getItem('language');
  } else {
    lng = 'lv';
  }
} catch (err) {
  lng = 'lv';
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'lv',
    debug: false,
    detection: {
      order: ['localStorage'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage'],
    },

    interpolation: {
      escapeValue: false,
    },
    lng,
    resources: {
      lv: lvTranslations,
      ru: ruTranslations,
      en: enTranslations,
    },
  });


export default i18n;
