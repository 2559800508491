import { errorMessages } from '../constants/messages';
import { Firebase, FirebaseRef } from '../lib/firebase';

import config from '../constants/config';

function isUserEqual(facebookAuthResponse, firebaseUser) {
  if (firebaseUser) {
    const { providerData } = firebaseUser;

    for (let i = 0; i < providerData.length; i += 1) {
      if (providerData[i].providerId === Firebase.auth.FacebookAuthProvider.PROVIDER_ID
        && providerData[i].uid === facebookAuthResponse.userID) {
        // We don't need to re-auth the Firebase connection.
        return true;
      }
    }
  }
  return false;
}

export default {
  state: {}, // initial state

  /**
   * Reducers
   */
  reducers: {
    setUserLogin(state, payload) {
      const { uid, email, emailVerified } = payload;

      return {
        ...state,
        uid,
        email,
        emailVerified,
      };
    },

    setUserDetails(_state, payload) {
      payload = payload || {};

      return {
        //...state,
        ...payload
      };
    },

    resetUser() {
      return {};
    },
  },

  /**
   * Effects/Actions
   */
  effects: dispatch => ({
    /**
     * Sign Up
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    signUp(formData) {
      const {
        email, password, password2, firstName, lastName,
      } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!firstName) return reject({ message: errorMessages.missingFirstName });
        if (!lastName) return reject({ message: errorMessages.missingLastName });
        if (!email) return reject({ message: errorMessages.missingEmail });
        if (!password) return reject({ message: errorMessages.missingPassword });
        if (!password2) return reject({ message: errorMessages.missingPassword });
        if (password !== password2) return reject({ message: errorMessages.passwordsDontMatch });

        // Go to Firebase
        return Firebase.auth().createUserWithEmailAndPassword(email, password)
          .then((res) => {
            // Send user details to Firebase database
            if (res && res.user.uid) {
              FirebaseRef.child(`users/${res.user.uid}`).set({
                firstName,
                lastName,
                signedUp: Firebase.database.ServerValue.TIMESTAMP,
                lastLoggedIn: Firebase.database.ServerValue.TIMESTAMP,
              }).then(resolve);
            }
          }).catch(reject);
      }).catch((err) => { throw err.message; });
    },

    /**
     * Listen for realtime updates on the current user
     */
    _listenForMemberProfileUpdates(snapshot) {
      const userData = snapshot.val() || [];
      this.setUserDetails(userData);
    },

    listenForMemberProfileUpdates() {
      const UID = (
        FirebaseRef
        && Firebase
        && Firebase.auth()
        && Firebase.auth().currentUser
        && Firebase.auth().currentUser.uid
      ) ? Firebase.auth().currentUser.uid : null;

      if (!UID) return false;

      const ref = FirebaseRef.child(`users/${UID}`);

      ref.off('value', this._listenForMemberProfileUpdates);
      return ref.on('value', this._listenForMemberProfileUpdates);
    },

    /**
     * Get the current Member's Details
     *
     * @returns {Promise}
     */
    getMemberData() {
      if (Firebase === null) return new Promise(resolve => resolve);

      // Ensure token is up to date
      return new Promise((resolve) => {
        Firebase.auth().onAuthStateChanged((loggedIn) => {
          if (loggedIn) {
            this.listenForMemberProfileUpdates(dispatch);
            return resolve();
          }

          return new Promise(() => resolve);
        });
      });
    },

    /**
     * Login to Firebase with Email/Password
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    login(formData) {
      const { email, password } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email || email.length === 0) return reject({ message: errorMessages.missingEmail });
        if (!password || password.length === 0) {
          return reject({ message: errorMessages.missingPassword });
        }

        // Go to Firebase
        return Firebase.auth().setPersistence(Firebase.auth.Auth.Persistence.LOCAL)
          .then(() => Firebase.auth().signInWithEmailAndPassword(email, password)
            .then(async (res) => {
              const userDetails = res && res.user ? res.user : null;

              // Save the user's login data (email, UID)
              this.setUserLogin(userDetails);

              // Update last logged in data
              if (userDetails.uid) {
                FirebaseRef.child(`users/${userDetails.uid}`).update({
                  lastLoggedIn: Firebase.database.ServerValue.TIMESTAMP,
                });

                // Send verification Email when email hasn't been verified
                if (userDetails.emailVerified === false) {
                  Firebase.auth().currentUser.sendEmailVerification()
                    .catch(() => console.error('Verification email failed to send'));
                }

                // Get/Save User Profile (name, signed up date etc)
                this.listenForMemberProfileUpdates(dispatch);
              }

              return resolve();
            }).catch(reject));
      }).catch((err) => { throw err.message; });
    },

    /**
     * Reset Password
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    resetPassword(formData) {
      const { email } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });

        // Go to Firebase
        return Firebase.auth().sendPasswordResetEmail(email)
          .then(() => {
            this.resetUser();
            resolve();
          }).catch(reject);
      }).catch((err) => { throw err.message; });
    },

    /**
     * Update Profile
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    updateProfile(formData) {
      const {
        email, password, password2, firstName, lastName, changeEmail, changePassword,
      } = formData;

      return new Promise(async (resolve, reject) => {
        // Are they a user?
        const UID = await Firebase.auth().currentUser.uid;
        if (!UID) return reject({ message: errorMessages.memberNotAuthd });

        // Validation rules
        if (!firstName) return reject({ message: errorMessages.missingFirstName });
        if (!lastName) return reject({ message: errorMessages.missingLastName });
        if (changeEmail) {
          if (!email) return reject({ message: errorMessages.missingEmail });
        }
        if (changePassword) {
          if (!password) return reject({ message: errorMessages.missingPassword });
          if (!password2) return reject({ message: errorMessages.missingPassword });
          if (password !== password2) return reject({ message: errorMessages.passwordsDontMatch });
        }

        // Go to Firebase
        return FirebaseRef.child(`users/${UID}`).update({ firstName, lastName })
          .then(async () => {
            // Update Email address
            if (changeEmail) {
              await Firebase.auth().currentUser.updateEmail(email).catch(reject);
            }

            // Change the Password
            if (changePassword) {
              await Firebase.auth().currentUser.updatePassword(password).catch(reject);
            }

            return resolve();
          }).catch(reject);
      }).catch((err) => { throw err.message; });
    },

    /**
     * Logout
     *
     * @returns {Promise}
     */
    logout() {
      return new Promise((resolve, reject) => Firebase.auth().signOut()
        .then(() => {
          this.resetUser();
          resolve();
        }).catch(reject)).catch((err) => { throw err.message; });
    },

    draugiemAuth(authCode) {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const draugiemAuthFunction = Firebase.app().functions().httpsCallable('draugiemAuth');

      return new Promise((resolve, _reject) => draugiemAuthFunction({ authCode, devMode: config.DEV }).then((result) => {
        const { data } = result;

        if (data.status === 'success') {
          if (data.token) {
            Firebase.auth().setPersistence(Firebase.auth.Auth.Persistence.SESSION).then(() => {

              Firebase.auth().signInWithCustomToken(data.token).then((promisesRes) => {

                const res = promisesRes[0];

                if (res.data && res.data.uid) {
                  return FirebaseRef.child(`users/${res.data.uid.toString()}`)
                    .once('value', (userSnapshot) => {
                      const playerData = userSnapshot.val() || {};
                      const { uid } = playerData;

                      if (uid) {
                        Firebase.database().ref(`users/${uid}`).update({
                          lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                        });
                      }

                      return resolve();
                    });

                } else if (res.operationType === 'signIn' && res.user && res.user.uid) {
                  return FirebaseRef.child(`users/${res.user.uid.toString()}`)
                    .once('value', (playerSnapshot) => {
                      const playerData = playerSnapshot.val() || {};
                      const { uid } = playerData;

                      if (uid) {
                        Firebase.database().ref(`users/${uid}`).update({
                          lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                        });
                      }

                      return resolve();
                    });
                }
              }).catch((error) => {
                throw error.message;
              });
            }).catch((error) => {
              throw error.message;
            });
          } else {
            return _dispatch => new Promise((resolve, reject) => Firebase.auth().signOut()
              .then(() => {
                return resolve();
              })
              .catch(reject)).catch((err) => {
                throw err.message;
              });
          }
        } else {
          return _dispatch => new Promise((resolve, reject) => Firebase.auth().signOut()
            .then(() => {
              return resolve();
            })
            .catch(reject)).catch((err) => {
              throw err.message;
            });
        }
      }));
    },


    checkLoginState(event) {
      return new Promise((resolve, _reject) => {
        if (event.authResponse) {

          const unsubscribe = Firebase.auth().onAuthStateChanged((firebaseUser) => {
            unsubscribe();
            // Check if we are already signed-in Firebase with the correct user.

            if (!isUserEqual(event.authResponse, firebaseUser)) {
              // Build Firebase credential with the Facebook auth token.
              const credential = Firebase.auth.FacebookAuthProvider.credential(
                event.authResponse.accessToken,
              );

              // Sign in with the credential from the Facebook user.
              Firebase.auth().signInWithCredential(credential).then((res) => {

                const afterFBAuthFunction = Firebase.app().functions().httpsCallable('afterFBAuth');

                afterFBAuthFunction({
                  uid: res.user.uid,
                  providerData: res.user.providerData,
                  additionalUserInfo: res.additionalUserInfo,
                }).then(() => {
                  this.listenForMemberProfileUpdates(dispatch);
                  return resolve();
                }).catch((err) => {
                  console.error('FB Auth problem');
                  console.error(err);
                });
              }).catch((err) => {
                console.error('FB signIn problem');
                console.error(err);
              });
            } else {

              FirebaseRef.child(`users/${firebaseUser.uid}`)
                .once('value', (userSnapshot) => {
                  const userData = userSnapshot.val() || {};

                  Firebase.database().ref(`users/${firebaseUser.uid}`).update({
                    lastLogin: Firebase.database.ServerValue.TIMESTAMP,
                  });

                  if (firebaseUser && (!userData || !userData.uid)) {
                    const afterFBAuthFunction = Firebase.app().functions().httpsCallable('afterFBAuth');

                    afterFBAuthFunction({
                      uid: firebaseUser.uid,
                      providerData: firebaseUser.providerData,
                    }).then(() => {
                      this.listenForMemberProfileUpdates(dispatch);
                      return resolve();
                    }).catch((err) => {
                      console.error('FB Auth problem');
                      console.error(err);
                    });
                  } else {
                    this.listenForMemberProfileUpdates(dispatch);
                    return resolve();
                  }
                });

            }
          });
        } else {
          // User is signed-out of Facebook.
          Firebase.auth().signOut();
          this.resetUser();
          return resolve();
        }
      })
    },

    checkDayBonus() {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      return new Promise((resolve, reject) => {
        const unsubscribe = Firebase.auth().onAuthStateChanged((loggedIn) => {
          //unsubscribe();
          if (loggedIn) {
            const checkDayBonusFunction = Firebase.app().functions().httpsCallable('checkDayBonus');
            checkDayBonusFunction().then((res) => {
              return resolve(res);
            }).catch(reject).catch((err) => { throw err; });
          } else {
            return resolve({ data: 0 });
          }
        })
      });
    }

  })
}
