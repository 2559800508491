import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Badge from '../../UI/Badge';

import { playerLevels } from '../../../../constants/mappings';

import gameMoneyImg from '../../../../images/icons/game-money.svg';
import winIconImg from '../../../../images/icons/wins.svg';
import lossesIconImg from '../../../../images/icons/losses.svg';

import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col
} from 'reactstrap';

const ChooseLevel = ({ t, data, index, disabled, onChangeLevel }) => {

  let mc;
  let whatever = false;

  switch (data.name) {
    case "beginner":
      mc = 0;
      break;
    case "master":
      mc = 1000;
      break;
    case "ultra":
      mc = 1500;
      break;
    case "expert":
      mc = 750;
      break;
    case "guru":
      mc = 1250;
      break;
    default:
      whatever = true;
      break;
  }

  return (
    <Col className="radio-inline create-game-level" xs="6" ms="6" md="6" lg="4">
      <Label className={`fancy-radio ${disabled ? 'disabled' : ''}`}>
        <Input onChange={onChangeLevel} disabled={disabled} type="radio" name="choose-level" value={index} />
        <span className="checkmark"></span>
        <span className="create-game-level-name">
          {t(`level.${data.name}`)}
          <Badge mc={mc} whatever={whatever} />
        </span>
      </Label>
    </Col>
  );
};

ChooseLevel.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  index: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChangeLevel: PropTypes.func.isRequired,
};

class CreateGame extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    createTable: PropTypes.func.isRequired,
    member: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      levelChosen: null,
      gameProcessed: false
    };
  }

  state = {
    levelChosen: null
  }

  onChangeLevel = (e) => {
    this.setState({ levelChosen: e.target.value })
  }

  onCreateGame = () => {
    const { createTable } = this.props;
    const { levelChosen } = this.state;

    this.setState({ gameProcessed: true });

    createTable(levelChosen).then(() => {
      this.setState({ gameProcessed: false });
    });
  }

  render = () => {

    const { loading, t, member } = this.props;
    const { gameProcessed, levelChosen } = this.state;

    const joined = (member && member.joinedTable) ? true : false;

    return (
      <Card className="create-game-card">

        <CardBody>
          <CardTitle>{t('menu.createOwnGame')}:</CardTitle>
          <CardSubtitle>{t('menu.opponentLevelHasToBe')}:</CardSubtitle>

          <Row className="create-game-content">
            <Col xs="12" sm="12" md="8">
              <Row>
                {Object.keys(playerLevels).map((index) => {
                  return <ChooseLevel
                    key={`choose-level-${index}`}
                    t={t}
                    data={playerLevels[index]}
                    index={index}
                    disabled={loading || gameProcessed || joined}
                    onChangeLevel={this.onChangeLevel}
                  />
                })}
              </Row>
            </Col>
            <Col xs="12" sm="12" md="4" className="text-center">
              <div className="create-game-info-box">
                <span><img src={winIconImg} alt="" />0.8 RSP</span>
                <span><img src={lossesIconImg} alt="" />0.2 RSP</span>
              </div>
              <Button className="create-game-button" color="danger" onClick={this.onCreateGame} disabled={loading || gameProcessed || !levelChosen || joined}>
                1 <img src={gameMoneyImg} alt="" /> {t('menu.createOwnGame')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation('common')(CreateGame);