import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import config from '../../../constants/config';


class Footer extends Component {
  static propTypes = {
    fetchStatus: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    status: PropTypes.shape().isRequired
  }

  componentDidMount() {
    const { fetchStatus } = this.props;
    fetchStatus();
  }

  inviteFriend = () => {
    const { t } = this.props;
    if (config.isDraApp() && window.draugiemSendInvite) {
      window.draugiemSendInvite(t('friends.invite'));
    }
  }

  render() {

    const { status, t } = this.props;

    return (
      <footer className="footer">
        <Row>
          <Col sm="12" className="text-left">
            <Link to="/">{t('footer.reportABug')}</Link>
            <Link to="/">{t('footer.statistics')}</Link>
            <a href="#" onClick={this.inviteFriend}>{t('footer.inviteFriends')}</a>
            <span id="footer-online-status">{t('footer.online')}: {status.playersOnline || 0}/{status.playerInGame || 0}</span>
            <UncontrolledTooltip placement="top" target="footer-online-status">
              {t('footer.onlinePlayersInGamePlayers')}
            </UncontrolledTooltip>
          </Col>
        </Row>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  status: state.status || {},
});

const mapDispatchToProps = dispatch => ({
  fetchStatus: dispatch.status.getStatus
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('common')
)(Footer);