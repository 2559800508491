import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import gsap, { Linear, Sine } from "gsap";

import CreateGameComponent from './UI/CreateGame';
import GamesListComponent from './UI/GamesList';
import PlayerProfile from './UI/PlayerProfile'

import termoIconImg from '../../../images/menu/termo.svg';
import gameMoneyImg from '../../../images/icons/game-money.svg';
import emptyProfilePicImg from '../../../images/profile-placeholder.png';

import {
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  CardBody,
  CardTitle
} from 'reactstrap';


class Menu extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    member: PropTypes.shape().isRequired,
    tables: PropTypes.shape().isRequired,
    gameState: PropTypes.shape().isRequired,
    todaysLB: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    error: PropTypes.string,
    success: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    createTable: PropTypes.func.isRequired,
    leaveTable: PropTypes.func.isRequired,
    joinTable: PropTypes.func.isRequired,
    closeTable: PropTypes.func.isRequired,
    flushError: PropTypes.func.isRequired,
    dayBonus: PropTypes.number
  }

  state = {
    termoAnimating: false,
    dayBonusClosed: false
  }

  constructor(props) {
    super(props);
    this.termoBarRef = React.createRef();
  }

  closeErrorModal = () => {
    const { flushError } = this.props;
    flushError();
  }

  componentDidMount() {
    const { member } = this.props;
    if (member && member.mc) {
      this._animateTermo();
    }
  }

  componentDidUpdate() {
    const { member } = this.props;
    if (member && member.mc) {
      this._animateTermo();
    }
  }

  _animateTermo = () => {
    const { member } = this.props;
    const { termoAnimating } = this.state;

    if (!termoAnimating) {
      const fullDuration = 3;

      this.setState({ termoAnimating: true });
      let mc = member.mc || 0;

      setTimeout(() => {
        let full = false;

        if (mc <= 250) {
          mc = 0;
        } else if (mc >= 1500) {
          full = true;
        } else {
          mc = mc - 250;
        }

        let perc;
        let part;

        part = Math.min(1250, mc) / 1250;

        if (full) {
          perc = 0;
        } else {
          perc = -94.3 + Math.round(part * 73.3);
        }

        const duration = fullDuration * part; // NOTE: rework?

        gsap.to(this.termoBarRef.current, {
          duration: duration, x: `${perc}%`, ease: Sine.easeInOut, onComplete: () => {
            // Empty for now
          }
        });

        // NOTE: rework?
        var tl = gsap.timeline({
          repeat: 0, paused: true, onUpdate: () => {
            if (tl.progress() >= part) {
              tl.pause();
            }
          }
        });

        const calcColorDur = (1 / part) * duration;
        const eachColorDur = calcColorDur / 5;

        if (duration > 0) {
          tl.fromTo(this.termoBarRef.current, { background: `#81BF40` }, { duration: (eachColorDur * 2), background: `#1BAB91`, ease: Linear.easeNone });
          tl.fromTo(this.termoBarRef.current, { background: `#1BAB91` }, { duration: eachColorDur, background: `#FDBB0D`, ease: Linear.easeNone });
          tl.fromTo(this.termoBarRef.current, { background: `#FDBB0D` }, { duration: eachColorDur, background: `#DF5121`, ease: Linear.easeNone });
          tl.fromTo(this.termoBarRef.current, { background: `#DF5121` }, { duration: eachColorDur, background: `#DE3155`, ease: Linear.easeNone });
          tl.play();
        }

      }, 1000);
    }
  }

  closeDayBonusModal = () => {
    this.setState({ dayBonusClosed: true });
  }

  render = () => {
    const {
      loading,
      createTable,
      error,
      t,
      member,
      closeTable,
      tables,
      joinTable,
      leaveTable,
      gameState,
      dayBonus,
      todaysLB
    } = this.props;

    const {
      dayBonusClosed
    } = this.state;

    if (!member || !member.uid) {
      return null; // NOTE: some message about unauthorized
    }

    return (
      <Fragment>

        <Row>
          <Col xs="12" sm="12" md="4">

            <div className="termo-box">
              <span>{t('menu.myMasteryTermo')}:</span>
              <div className="termo-wrapper">
                <div className="termo-bar-bg" />
                <div ref={this.termoBarRef} className="termo-bar" style={{ transform: 'translate(-94.3%, 0px)' }} />
                <img src={termoIconImg} alt="Termometer" />
              </div>
            </div>

            <Card className="profile-card">
              <CardBody className="profile-box">
                <CardTitle>{t('profile')}</CardTitle>
                <PlayerProfile playerData={member} t={t} compact={false} />
              </CardBody>
            </Card>

            <Card className="top-10-card">
              <CardBody>
                <CardTitle>{t('top.todaysTop10')}</CardTitle>
                <ul>
                  {todaysLB.map((val, i) => {
                    return <li key={`todays-top-10-${i}`}>
                      <span>{i + 1}.</span>
                      <img src={val.photo || emptyProfilePicImg} alt="" />
                      <span>{val.firstName}&nbsp;{val.lastName && val.lastName[0]}</span>
                      <span className="top-10-card-plus-wrap"><span className="top-10-card-plus">{val.mc > 0 ? ('+' + val.mc) : val.mc}</span></span>
                    </li>
                  })}
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="8">
            <CreateGameComponent createTable={createTable} loading={loading} member={member} />
            <GamesListComponent
              loading={loading}
              member={member}
              tables={tables}
              joinTable={joinTable}
              leaveTable={leaveTable}
              closeTable={closeTable}
              gameState={gameState}
            />
          </Col>

        </Row>

        <Modal isOpen={error ? true : false} className="error-modal">
          <ModalHeader toggle={this.closeErrorModal}>{t('modal.error')}</ModalHeader>
          <ModalBody>
            {error ? t(`error.${error}`) : ''}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeErrorModal}>{t('modal.close')}</Button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={!!dayBonus && dayBonus > 0 && (dayBonusClosed === false)} className="day-bonus-modal">
          <ModalHeader toggle={this.closeDayBonusModal}>{t('modal.dayBonus')}</ModalHeader>
          <ModalBody>
            <div className="day-bonus-circle">
              <span>{dayBonus}</span>
              <img src={gameMoneyImg} alt="" />
            </div>
            <p>{t('modal.dayBonusInviteTxt')}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeDayBonusModal}>{t('modal.take')}</Button>
          </ModalFooter>
        </Modal>

      </Fragment>
    );
  }
}

export default withTranslation('common')(Menu);
