import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col
} from 'reactstrap';

import emptyProfilePicImg from '../../../../images/profile-placeholder.png';

class GameListItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    joinTable: PropTypes.func,
    leaveTable: PropTypes.func,
    closeTable: PropTypes.func,
    tables: PropTypes.shape(),
    disabled: PropTypes.bool.isRequired,
    type: PropTypes.number.isRequired,
    member: PropTypes.shape().isRequired
  }

  state = {
    gameProcessed: false
  }

  onCloseGame = () => {
    const { closeTable } = this.props;

    this.setState({ gameProcessed: true });

    closeTable().then(() => {
      this.setState({ gameProcessed: false });
    });
  }

  onJoinGame = (tableId) => {
    const { joinTable } = this.props;

    this.setState({ gameProcessed: true });

    joinTable(tableId).then(() => {
      this.setState({ gameProcessed: false });
    });
  }

  onLeaveTable = () => {
    const { leaveTable } = this.props;

    this.setState({ gameProcessed: true });

    leaveTable().then(() => {
      this.setState({ gameProcessed: false });
    });
  }

  render = () => {

    const { disabled, t, tables, type/*, member*/ } = this.props;
    const { gameProcessed } = this.state;

    return (
      <Fragment>
        {Object.keys(tables).map((index) => {
          return <li key={index} className={`game-list-el ${(type === 1 || type === 2) ? 'game-list-el-joined' : ''}`}>
            <Row>
              <Col xs="4">
                <img src={tables[index].player1.data.photo || emptyProfilePicImg} alt="Player" />
                <span>{tables[index].player1.name}</span>
              </Col>
              <Col xs="4">
                <img src={emptyProfilePicImg} alt="Player" />
                <span>{tables[index].player2 ? tables[index].player2.name : '?'}</span>
              </Col>
              <Col xs="4" className="game-list-el-buttons">
                {type === 1 && (
                  <Button className="shadow-lite" onClick={() => this.onCloseGame()} disabled={disabled || gameProcessed}>{t('menu.close')}</Button>
                )}
                {type === 2 && (
                  <Button className="shadow-lite" onClick={() => this.onLeaveTable()} disabled={disabled || gameProcessed}>{t('menu.leave')}</Button>
                )}
                {type === 3 && (
                  <Button className="shadow-lite" onClick={() => this.onJoinGame(index)} disabled={disabled || gameProcessed}>{t('menu.join')}</Button>
                )}
              </Col>
            </Row>
          </li>
        })}
      </Fragment>
    );
  }
}

class GamesList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    joinTable: PropTypes.func.isRequired,
    leaveTable: PropTypes.func.isRequired,
    closeTable: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    gameState: PropTypes.shape().isRequired,
    member: PropTypes.shape().isRequired,
    tables: PropTypes.shape(),
  }

  constructor(props) {
    super(props);
    this.state = {
      ownedTables: {},
      joinedTables: {},
      otherTabels: {}
    };
  }

  state = {

  }

  static getDerivedStateFromProps(props, _state) {
    const { member, tables } = props;

    let ownedTables = {};
    let joinedTables = {};
    let otherTabels = {};

    if (member && member.uid) {
      Object.keys(tables).forEach((index) => {
        if (!tables[index].player2) {
          if (tables[index].player1.uid === member.uid) {
            ownedTables[index] = tables[index];
          }
          else if (tables[index].player2 && tables[index].player2.uid === member.uid) {
            joinedTables[index] = tables[index];
          }
          else {
            otherTabels[index] = tables[index];
          }
        }
      });
    }

    return {
      ownedTables,
      joinedTables,
      otherTabels
    };
  }

  render = () => {

    const { loading, t, joinTable, leaveTable, closeTable, gameState, member } = this.props;
    const {
      ownedTables,
      joinedTables,
      otherTabels
    } = this.state;

    const inGame = gameState && gameState.closed === false;
    const actionOff = loading || inGame;

    return (
      <Card className="game-list">
        <CardBody>
          <CardTitle>{t('menu.createdGames')}:</CardTitle>
          <Row className="game-list-content">
            <Col xs="12" sm="12" md="12">
              <ul>
                <GameListItem
                  t={t}
                  disabled={actionOff}
                  tables={ownedTables}
                  type={1}
                  closeTable={closeTable}
                  member={member}
                />
                <GameListItem
                  t={t}
                  disabled={actionOff}
                  tables={joinedTables}
                  type={2}
                  leaveTable={leaveTable}
                  member={member}
                />
                <GameListItem
                  t={t}
                  disabled={actionOff}
                  tables={otherTabels}
                  type={3}
                  joinTable={joinTable}
                  member={member}
                />
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation('common')(GamesList);