const playerLevels = {
    "1": {
        "name": "beginner"
    },
    "2": {
        "name": "expert"
    },
    "3": {
        "name": "master"
    },
    "4": {
        "name": "guru"
    },
    "5": {
        "name": "ultra"
    },
    "6": {
        "name": "whatever"
    }
}

export {
    playerLevels
}