import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import gsap, { Linear, Sine } from "gsap";

import termoIconImg from '../../../images/menu/termo-brighter-bg.svg';
import goldBlueAwarsImg from '../../../images/Awards/GoldBlue.svg';
import goldGreenAwarsImg from '../../../images/Awards/GoldGreen.svg';
import goldRedAwarsImg from '../../../images/Awards/GoldRed.svg';
import silverBlueAwarsImg from '../../../images/Awards/SilverBlue.svg';
import silverGreenAwarsImg from '../../../images/Awards/SilverGreen.svg';
import trophyGoldAwarsImg from '../../../images/Awards/TrophyGold.svg';

class Awards extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    error: PropTypes.string,
    success: PropTypes.string,
    member: PropTypes.shape().isRequired,
    awards: PropTypes.shape().isRequired
  }

  state = {
    termoAnimating: false
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);
    this.termoBarRef = React.createRef();
  }

  componentDidMount() {
    const { member } = this.props;
    if (member && member.mc) {
      this._animateTermo();
    }
  }

  componentDidUpdate() {
    const { member } = this.props;
    if (member && member.mc) {
      this._animateTermo();
    }
  }

  _animateTermo = () => {
    const { member } = this.props;
    const { termoAnimating } = this.state;

    if (!termoAnimating) {
      const fullDuration = 3;

      this.setState({ termoAnimating: true });
      let mc = member.mc || 0;

      setTimeout(() => {
        let full = false;

        if (mc <= 250) {
          mc = 0;
        } else if (mc >= 1500) {
          full = true;
        } else {
          mc = mc - 250;
        }

        let perc;
        let part;

        part = Math.min(1250, mc) / 1250;

        if (full) {
          perc = 0;
        } else {
          perc = -94.3 + Math.round(part * 73.3);
        }

        const duration = fullDuration * part; // NOTE: rework?

        gsap.to(this.termoBarRef.current, {
          duration: duration, x: `${perc}%`, ease: Sine.easeInOut, onComplete: () => {
            // Empty for now
          }
        });

        // NOTE: rework?
        var tl = gsap.timeline({
          repeat: 0, paused: true, onUpdate: () => {
            if (tl.progress() >= part) {
              tl.pause();
            }
          }
        });

        const calcColorDur = (1 / part) * duration;
        const eachColorDur = calcColorDur / 5;

        if (duration > 0) {
          tl.fromTo(this.termoBarRef.current, { background: `#81BF40` }, { duration: (eachColorDur * 2), background: `#1BAB91`, ease: Linear.easeNone });
          tl.fromTo(this.termoBarRef.current, { background: `#1BAB91` }, { duration: eachColorDur, background: `#FDBB0D`, ease: Linear.easeNone });
          tl.fromTo(this.termoBarRef.current, { background: `#FDBB0D` }, { duration: eachColorDur, background: `#DF5121`, ease: Linear.easeNone });
          tl.fromTo(this.termoBarRef.current, { background: `#DF5121` }, { duration: eachColorDur, background: `#DE3155`, ease: Linear.easeNone });
          tl.play();
        }

      }, 1000);
    }
  }


  render() {

    const { t, awards } = this.props;

    return (
      <Row className="awards-content">
        <Col xs="12">
          <Card>
            <CardBody>

              <div className="termo-box">
                <span>{t('menu.myMasteryTermo')}:</span>
                <div className="termo-wrapper">
                  <div className="termo-bar-bg" />
                  <div ref={this.termoBarRef} className="termo-bar" style={{ transform: 'translate(-94.3%, 0px)' }} />
                  <img src={termoIconImg} alt="Termometer" />
                </div>
              </div>

              <div className="awards-title">{t('awards.myAwards')}</div>

              <div className="awards-row">
                <div>
                  <img src={goldGreenAwarsImg} alt="" />
                  <span className="award-name">{t('awards.fiveWinsInRow')}</span>
                  <span className="award-level">{t('awards.awardLevel')}: {awards["5winsInRow"] || 0}</span>
                </div>
                <div>
                  <img src={goldBlueAwarsImg} alt="" />
                  <span className="award-name">{t('awards.tenWinsInRow')}</span>
                  <span className="award-level">{t('awards.awardLevel')}: {awards["10winsInRow"] || 0}</span>
                </div>
                <div>
                  <img src={goldRedAwarsImg} alt="" />
                  <span className="award-name">{t('awards.25WinsInRow')}</span>
                  <span className="award-level">{t('awards.awardLevel')}: {awards["25winsInRow"] || 0}</span>
                </div>
                <div>
                  <img src={silverGreenAwarsImg} alt="" />
                  <span className="award-name" style={{ paddingTop: '4px' }}>{t('awards.50gamesInDay')}</span>
                  <span className="award-level">{t('awards.awardLevel')}: {awards["50gamesInDay"] || 0}</span>
                </div>
                <div>
                  <img src={silverBlueAwarsImg} alt="" />
                  <span className="award-name" style={{ paddingTop: '4px' }}>{t('awards.300gamesInDay')}</span>
                  <span className="award-level">{t('awards.awardLevel')}: {awards["300gamesInDay"] || 0}</span>
                </div>
              </div>

              <div className="awards-row awards-row-tour">
                <div>
                  <img src={trophyGoldAwarsImg} alt="" />
                  <span className="award-name">{t('awards.tournamentsWon')}</span><br />
                  <span className="award-level">{t('awards.awardLevel')}: {awards["tournamentsWon"] || 0}</span>
                </div>
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row>

    );
  }
}


export default withTranslation('common')(Awards);