import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Checkers from '../../../lib/checkers';
import gsap from "gsap";


import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col
} from 'reactstrap';

import Timer from './UI/Timer';
import PlayerProfile from '../Menu/UI/PlayerProfile'

import blackPieceImg from '../../../images/board/black-piece.svg';
import whitePieceImg from '../../../images/board/white-piece.svg';
import blackKingPieceImg from '../../../images/board/black-king-piece.svg';
import whiteKingPieceImg from '../../../images/board/white-king-piece.svg';
import selectedRingImg from '../../../images/board/selected-ring.svg';
import emptyProfilePicImg from '../../../images/profile-placeholder.png';
import fakeTimerImg from '../../../images/game/faketimer.svg';
//import member from '../../../models/member';


class Game extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    member: PropTypes.shape().isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape()
    ]),
    success: PropTypes.string,
    tableId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    flushError: PropTypes.func.isRequired,
    leaveTable: PropTypes.func.isRequired,
    makeMove: PropTypes.func.isRequired,
    nextGame: PropTypes.func.isRequired,
    timeout: PropTypes.func.isRequired,
    gameState: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    table: PropTypes.shape().isRequired
  }

  state = {
    gameProcessed: false,
    iAm: null,
    opponentIs: null,
    myData: null,
    opponentData: null,
    myTmpData: null,
    opponentTmpData: null,
    boardNums: [],
    boardLetters: [],
    myAllowedMoves: {},
    mySelectedPiece: null,
    myTmpSelectedPiece: null,
    myTurn: null,
    turn: null,
    moveArr: [],
    knockInPrevMove: false,
    tmpDataUpdated: null,
    newGameAt: null,
    gsUpdatedAt: null,
    koefWin: null,
    koefLosse: null
  }

  constructor(props) {
    super(props);
    this.boardContWrapRef = React.createRef();
    this.boardRef = React.createRef();
    this.resizeTimeout = null;

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions(100);
    this._calcElo();
    window.addEventListener("resize", () => { this.updateDimensions(700); });
  }

  componentDidUpdate() {
    this._calcElo();
  }

  static getDerivedStateFromProps(props, state) {
    const { gameState, member } = props;
    const {
      iAm: iAmFS,
      opponentIs: opponentIsFS,
      myTmpData,
      opponentTmpData,
      mySelectedPiece,
      moveArr,
      knockInPrevMove,
      tmpDataUpdated,
      newGameAt: newGameAtFS,
      gsUpdatedAt
    } = state;

    if ((gameState && gameState.player1 && gameState.playerData) && (member && member.uid)) {

      const { playerData, newGameAt, updatedAt } = gameState;

      let iAm;
      let opponentIs;
      let gameScopeData = {};

      if (newGameAtFS && newGameAtFS === newGameAt) {
        iAm = iAmFS;
        opponentIs = opponentIsFS;
      } else {
        if (gameState.player1 === member.uid) {
          iAm = "player1";
          opponentIs = "player2";
        } else {
          iAm = "player2";
          opponentIs = "player1";
        }

        const boardCells = Checkers.getCells(!playerData[iAm].starter);

        gameScopeData = {
          newGameAt: newGameAt,
          iAm: iAm,
          opponentIs: opponentIs,
          boardNums: boardCells.nums,
          boardLetters: boardCells.letters
        };
      }

      let turnScopeData = {};

      const gsUpdated = (!gsUpdatedAt || gsUpdatedAt !== updatedAt);

      if (tmpDataUpdated || gsUpdated) {
        turnScopeData["tmpDataUpdated"] = false;

        const myData = myTmpData ? myTmpData : playerData[iAm];
        const opponentData = opponentTmpData ? opponentTmpData : playerData[opponentIs];

        let myNewAllowedMoves = Checkers.allowedMoves(myData, opponentData);

        if (mySelectedPiece && moveArr.length > 0) {
          if (!knockInPrevMove || !Checkers.mustKnock(mySelectedPiece, myNewAllowedMoves)) {
            myNewAllowedMoves = {};
          }
        }

        turnScopeData["myAllowedMoves"] = myNewAllowedMoves;

        if (gsUpdated) {

          let myTurn = false;

          if (playerData.turn === iAm) {
            myTurn = true;
          }

          turnScopeData = {
            ...turnScopeData,
            myTurn,
            turn: playerData.turn,
            myData: playerData[iAm],
            opponentData: playerData[opponentIs],
            gsUpdatedAt: updatedAt
          };
        }
      }

      return { ...gameScopeData, ...turnScopeData };
    }

    return null;
  }

  updateDimensions(time) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    this.resizeTimeout = setTimeout(() => {
      const boardContEl = this.boardContWrapRef.current;
      const boardEl = this.boardRef.current;

      boardEl.style.width = `0px`;
      boardEl.style.height = `0px`;

      const computedStyle = getComputedStyle(boardContEl);

      const boardElWidth = boardContEl.clientWidth - (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight));
      const boardElHeight = boardContEl.clientHeight - (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));

      let boardSquareSize = boardElWidth;

      if (boardElHeight < boardElWidth) {
        boardSquareSize = boardElHeight;
      }

      boardEl.style.width = `${boardSquareSize - 20}px`;
      boardEl.style.height = `${boardSquareSize - 20}px`;
    }, time);
  }

  closeErrorModal = () => {
    const { flushError } = this.props;
    flushError();
  }

  onLeaveTable = () => {
    const { leaveTable } = this.props;

    this.setState({ gameProcessed: true });

    leaveTable().then(() => {
      this.setState({ gameProcessed: false });
    });
  }

  onSelectPiece = (e, position) => {
    const { loading } = this.props;
    const { myTurn, myAllowedMoves, myTmpSelectedPiece } = this.state;
    e.stopPropagation();

    if (!myTmpSelectedPiece && myTurn && !loading && myAllowedMoves[position]) {
      this.setState({ mySelectedPiece: position });
    }
  }

  backToMenu = () => {
    const { history } = this.props;
    history.push('/');
  }

  _sendMovesToServer = (movesArr) => {
    const { makeMove } = this.props;
    const { myTmpSelectedPiece: from } = this.state;
    this.setState({ gameProcessed: true });

    if (movesArr && movesArr.length > 0) {
      makeMove(from, movesArr).then(() => {
        this.setState({ gameProcessed: false });
      });
    }
  }

  onMakeMove = (position) => {
    const { loading } = this.props;
    const {
      myAllowedMoves,
      mySelectedPiece,
      myTurn,
      myData,
      opponentData,
      myTmpSelectedPiece,
      myTmpData,
      opponentTmpData,
      moveArr
    } = this.state;

    if (!myTurn || loading) {
      console.error("move not allowed");
      return;
    }

    if (mySelectedPiece && myAllowedMoves && myAllowedMoves[mySelectedPiece]) {

      let _myTmpData = JSON.parse(JSON.stringify(myTmpData ? myTmpData : myData));
      let _opponentTmpData = JSON.parse(JSON.stringify(opponentTmpData ? opponentTmpData : opponentData));
      let moveResult;

      try {
        moveResult = Checkers.movePiece(mySelectedPiece, position, _myTmpData, _opponentTmpData, moveArr.length > 0);
      } catch (e) {
        console.error(e);
        return;
      }

      const selPieceEl = document.getElementById('selected-piece');

      if (selPieceEl) {
        const moveToCellEl = document.getElementsByClassName(`game-board-cell-${position}`)[0];
        const { left: moveToLeft, top: moveToTop } = moveToCellEl.getBoundingClientRect();
        const { left: moveFromLeft, top: moveFromTop } = selPieceEl.getBoundingClientRect();
        const leftDiff = moveToLeft - moveFromLeft;
        const topDiff = moveToTop - moveFromTop;
        gsap.to(selPieceEl, { duration: 0.5, x: `+=${leftDiff}`, y: `+=${topDiff}` });
      }

      let newMoveArr = moveArr.slice();
      newMoveArr.push(position);

      this.setState({
        myTmpData: _myTmpData,
        opponentTmpData: _opponentTmpData,
        myTmpSelectedPiece: myTmpSelectedPiece ? myTmpSelectedPiece : mySelectedPiece,
        tmpDataUpdated: true,

        mySelectedPiece: position,
        knockInPrevMove: moveResult.piecesKnocked,
        moveArr: newMoveArr
      });
    } else {
      console.error("move not allowed");
    }
  }

  _resetMovesState = () => {
    this.setState({
      mySelectedPiece: null,
      myTmpData: null,
      opponentTmpData: null,
      myTmpSelectedPiece: null,
      moveArr: [],
      knockInPrevMove: false
    });
  }

  onResetMoves = () => {
    const selPieceEl = document.getElementById('selected-piece');
    gsap.to(selPieceEl, { duration: 1, x: 0, y: 0 });
    this._resetMovesState();
  }

  oneSubmitMoves = () => {
    const { loading } = this.props;
    const { myAllowedMoves, mySelectedPiece, myTurn, moveArr } = this.state;

    if (loading || !myTurn || moveArr.length === 0) {
      console.error("can't end move now");
      return;
    }

    if (Object.keys(myAllowedMoves).length > 0 || !mySelectedPiece) {
      console.error("can't end move now");
      return;
    }

    this._sendMovesToServer(moveArr.slice());
    this._resetMovesState();
  }

  oneNextGame = () => {
    const { nextGame, gameState } = this.props;

    this.setState({ gameProcessed: true });

    if (gameState.winner) {
      nextGame().then(() => {
        this.setState({ gameProcessed: false });
      });
    }
  }

  handleTimeout = () => {
    /*const { timeout } = this.props;

    this.setState({ gameProcessed: true });

    timeout().then(() => {
      this.setState({ gameProcessed: false });
    });*/
  }

  _calcElo = () => {
    const { table } = this.props;
    const { iAm, opponentIs, koefWin } = this.state;

    if ((koefWin === null && iAm && opponentIs) && table && table.player1 && table.player2) {
      const myMc = table[iAm].data.mc;
      const oppMc = table[opponentIs].data.mc;

      const meWinElo = Checkers.calculateElo(myMc, oppMc, true);
      const meLosseElo = Checkers.calculateElo(myMc, oppMc, false);

      this.setState({ koefWin: Math.abs(meWinElo.myChange), koefLosse: Math.abs(meLosseElo.myChange) });
    }
  }

  render = () => {
    const {
      loading,
      error,
      t,
      gameState,
      table
    } = this.props;

    const {
      gameProcessed,
      myData,
      opponentData,
      boardNums,
      boardLetters,
      myAllowedMoves,
      mySelectedPiece,
      myTurn,
      myTmpSelectedPiece,
      iAm,
      opponentIs,
      turn,
      koefWin,
      koefLosse
    } = this.state;

    let allowedMoves = [];

    if (!loading && mySelectedPiece && myAllowedMoves[mySelectedPiece]) {
      myAllowedMoves[mySelectedPiece].forEach((movesArr) => {
        allowedMoves = allowedMoves.concat(movesArr.moveRange);
      });
    }

    const nextGameReqSent = (gameState.agreeOnNext && gameState.agreeOnNext.indexOf(iAm) > -1);

    let timeout = null;

    if (gameState && gameState.playerData && turn) {
      timeout = gameState.playerData[turn].timeout;
    }

    return (
      <Fragment>
        <div className="game-container">
          {/*<div className="game-main-container">*/}
          <div className="game-left-col">
            <Card>
              <CardBody>
                <div className="game-win-losse-koef">
                  <span><b>Uzvara</b>: +{koefWin} koef.</span>
                  <span><b>Zaudējums</b>: -{koefLosse} koef.</span>
                </div>
              </CardBody>
            </Card>
            <Card className={`${myTurn === false ? 'hl-card' : ''}`}>
              {(table && opponentIs && table[opponentIs]) && (
                <CardBody className="game-player-info-box">
                  <img src={table[opponentIs].data.photo || emptyProfilePicImg} alt="" />
                  <span>{table[opponentIs].name}</span>
                </CardBody>
              )}
            </Card>
            <Card>
              <CardBody>
                <Timer
                  onTimeout={this.handleTimeout}
                  timeout={timeout}
                />
              </CardBody>
            </Card>
            <Card className={`${myTurn === true ? 'hl-card' : ''}`}>
              {(table && iAm && table[iAm]) && (
                <CardBody className="game-player-info-box">
                  <img src={table[iAm].data.photo || emptyProfilePicImg} alt="" />
                  <span>{table[iAm].name}</span>
                </CardBody>
              )}
            </Card>
          </div>
          <div className="game-center-col">
            <div className="game-board-container">
              <div className="game-board-container-wrapper" ref={this.boardContWrapRef}>
                <div id="game-board" ref={this.boardRef}>
                  {boardNums.map((num) => {
                    return <div key={`board-row-${num}`} className={`game-board-row game-board-row-${num}`}>
                      <div className="game-board-row-indice">{num}</div>
                      {boardLetters.map((letter) => {
                        return <div
                          key={`board-cell-${letter}${num}`}
                          className={`game-board-cell game-board-cell-${letter}${num} ${allowedMoves.indexOf(`${letter}${num}`) > -1 ? 'game-board-cell-move' : ''}`}
                          onClick={() => { this.onMakeMove(`${letter}${num}`); }}
                        >
                          {myData && myData.pieces[`${letter}${num}`] &&
                            (<div className="game-board-piece-container" id={`${((mySelectedPiece && mySelectedPiece === `${letter}${num}`) || (myTmpSelectedPiece && myTmpSelectedPiece === `${letter}${num}`)) ? 'selected-piece' : ''}`}>
                              {myData.starter ?
                                <img className={!gameState.winner && !myTmpSelectedPiece && myTurn && !loading && (myAllowedMoves && myAllowedMoves[`${letter}${num}`]) ? `game-board-piece-active` : ''} onClick={(e) => { this.onSelectPiece(e, `${letter}${num}`); }} src={myData.pieces[`${letter}${num}`] === 1 ? whitePieceImg : whiteKingPieceImg} alt={`${letter}${num}white`} /> :
                                <img className={!gameState.winner && !myTmpSelectedPiece && myTurn && !loading && (myAllowedMoves && myAllowedMoves[`${letter}${num}`]) ? `game-board-piece-active` : ''} onClick={(e) => { this.onSelectPiece(e, `${letter}${num}`); }} src={myData.pieces[`${letter}${num}`] === 1 ? blackPieceImg : blackKingPieceImg} alt={`${letter}${num}black`} />}
                              {(!gameState.winner && ((mySelectedPiece && mySelectedPiece === `${letter}${num}`) || (myTmpSelectedPiece && myTmpSelectedPiece === `${letter}${num}`))) && (
                                <img className="game-board-piece-selected" onClick={(e) => { this.onSelectPiece(e, `${letter}${num}`); }} src={selectedRingImg} alt="" />
                              )}
                            </div>)
                          }
                          {opponentData && opponentData.pieces[`${letter}${num}`] &&
                            (<Fragment>
                              {opponentData.starter ?
                                <img src={opponentData.pieces[`${letter}${num}`] === 1 ? whitePieceImg : whiteKingPieceImg} alt={`${letter}${num}white`} /> :
                                <img src={opponentData.pieces[`${letter}${num}`] === 1 ? blackPieceImg : blackKingPieceImg} alt={`${letter}${num}black`} />}
                            </Fragment>)
                          }
                        </div>
                      })}
                    </div>
                  })}
                  <div className="game-board-col-indice-row">
                    {boardLetters.map((letter) => {
                      return <div key={`col-indice-${letter}`} className="game-board-col-indice">{letter}</div>
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="game-center-tools">
              <div>
                <Button disabled={gameProcessed || loading} onClick={this.onLeaveTable}>{t('game.endGame')}</Button>
                <Button disabled={gameProcessed || loading} onClick={this.onResetMoves}>{t('game.resetMove')}</Button>
                <Button disabled={gameProcessed || loading || Object.keys(myAllowedMoves).length > 0} onClick={this.oneSubmitMoves}>{t('game.submitMove')}</Button>
              </div>
            </div>
          </div>
          <div className="game-right-col">
            <div className="game-fake-timer">
              <img src={fakeTimerImg} alt="" />
            </div>
            <Card className="game-moves-list">
              <CardBody>
                <CardTitle>{t('game.moves')}:</CardTitle>
                {(Object.keys(table).length > 0 && gameState && gameState.playerData && gameState.playerData.moves) &&
                  (<ul>
                    {gameState.playerData.moves.slice().reverse().map((move) => {
                      return <li key={move.time}>{t('game.jumpsTo', { playerName: table[move.player].name, from: move.from, to: move.to.join(", ") })}</li>
                    })}
                  </ul>)}
              </CardBody>
            </Card>
          </div>
          {/*</div>          
          <div className="game-buttons-container">
            <div>
              <Button disabled={gameProcessed || loading} onClick={this.onLeaveTable}>{t('game.endGame')}</Button>
              <Button disabled={gameProcessed || loading} onClick={this.onResetMoves}>{t('game.resetMove')}</Button>
              <Button disabled={gameProcessed || loading || Object.keys(myAllowedMoves).length > 0} onClick={this.oneSubmitMoves}>{t('game.submitMove')}</Button>
            </div>
          </div>*/}
        </div>

        <Modal isOpen={error ? true : false} className={'error-modal'} toggle={this.closeErrorModal}>
          <ModalHeader toggle={this.closeErrorModal}>{t('modal.error')}</ModalHeader>
          <ModalBody>
            {t(`error.${error}`)}
          </ModalBody>
          <ModalFooter>
            <Button disabled={gameProcessed || loading} onClick={this.closeErrorModal}>{t('modal.close')}</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={gameState.closed === true ? true : false} className={'common-modal'} toggle={this.backToMenu}>
          <ModalHeader toggle={this.backToMenu}>{t('modal.message')}</ModalHeader>
          <ModalBody>
            {t(`error.${gameState.closedReason}`, { player: gameState.closedBy })}
          </ModalBody>
          <ModalFooter>
            <Button disabled={gameProcessed || loading} onClick={this.backToMenu}>{t('modal.close')}</Button>
          </ModalFooter>
        </Modal>

        <Modal size="lg" isOpen={gameState.winner && table ? true : false} className={'common-modal no-close'}>
          <ModalHeader>{t('modal.message')}</ModalHeader>
          <ModalBody>
            <h5>{gameState.winner === "none" ? t('game.aDraw') : t('game.winnerIs', { winner: table[gameState.winner] ? table[gameState.winner].name : '' })}</h5>
            <Row>
              {!!gameState.player1CurrData && (
                <Col xs="12" sm="12" md="6">
                  <Card>
                    <CardBody>
                      <PlayerProfile playerData={gameState.player1CurrData} t={t} />
                    </CardBody>
                  </Card>
                </Col>
              )}
              {!!gameState.player2CurrData && (
                <Col xs="12" sm="12" md="6">
                  <Card>
                    <CardBody>
                      <PlayerProfile playerData={gameState.player2CurrData} t={t} />
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button disabled={gameProcessed || loading || nextGameReqSent} onClick={this.oneNextGame}>{nextGameReqSent ? t('game.nextGameReqSent') : t('game.nextGame')}</Button>
            <Button disabled={gameProcessed || loading} onClick={this.onLeaveTable}>{t('game.endGame')}</Button>
          </ModalFooter>
        </Modal>

      </Fragment>
    );
  }
}

export default withTranslation('common')(Game);
