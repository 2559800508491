import React from 'react';
import PropTypes from 'prop-types';

import beginnerBageImg from '../../../images/badges/beginner.svg';
import expertBageImg from '../../../images/badges/expert.svg';
import guruBageImg from '../../../images/badges/guru.svg';
import masterBageImg from '../../../images/badges/master.svg';
import ultraBageImg from '../../../images/badges/ultra.svg';
import whateverBageImg from '../../../images/badges/whatever.svg';


const Badge = ({ mc, whatever }) => {
  let badgeImgSrc = null;

  if (!whatever && (mc === null || mc === undefined)) {
    return null;
  }

  if (whatever === true) {
    badgeImgSrc = whateverBageImg;
  } else {
    if (mc >= 0 && mc < 750) {
      badgeImgSrc = beginnerBageImg;
    } else if (mc >= 750 && mc < 1000) {
      badgeImgSrc = expertBageImg;
    } else if (mc >= 1000 && mc < 1250) {
      badgeImgSrc = masterBageImg;
    } else if (mc >= 1250 && mc < 1500) {
      badgeImgSrc = guruBageImg;
    } else if (mc >= 1500) {
      badgeImgSrc = ultraBageImg;
    }
  }

  return (
    <div className="mc-badge">
      <img src={badgeImgSrc} alt="" />
    </div>
  );
};

Error.propTypes = {
  mc: PropTypes.number,
  whatever: PropTypes.bool
};

Error.defaultProps = {
  mc: null,
  whatever: false
};

export default Badge;
