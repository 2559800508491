import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

class Game extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    member: PropTypes.shape().isRequired,
    table: PropTypes.shape().isRequired,
    leaveTable: PropTypes.func.isRequired,
    fetchGameState: PropTypes.func.isRequired,
    makeMove: PropTypes.func.isRequired,
    fetchTable: PropTypes.func.isRequired,
    nextGame: PropTypes.func.isRequired,
    timeout: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired
  }

  state = {
    error: null,
    success: null,
    loading: false,
    gameStateFetched: false
  }

  componentDidMount = () => this.fetchData();

  componentDidUpdate = () => {
    const { gameStateFetched } = this.state;
    if (gameStateFetched) this.checkInGame();
  }

  checkInGame = () => {
    const { gameState, history } = this.props;

    if (gameState.closed === undefined) {
      history.push('/menu');
      return true;
    }

    return false;
  };

  fetchData = () => {
    const { fetchGameState, fetchTable } = this.props;
    const { gameStateFetched } = this.state;

    this.setState({ loading: true });
    return this.handleAction(Promise.all([
      fetchGameState(this.props.match.params.id),
      fetchTable(this.props.match.params.id)
    ])).then(() => {
      if (!gameStateFetched) this.setState({ gameStateFetched: true });
      this.checkInGame();
    });
  }

  leaveTable = () => {
    const { leaveTable } = this.props;
    this.setState({ loading: true });
    return this.handleAction(leaveTable());
  }

  makeMove = (from, movesArr) => {
    const { makeMove } = this.props;
    this.setState({ loading: true });
    return this.handleAction(makeMove({from, movesArr, tableId: this.props.match.params.id}));
  }

  nextGame = () => {    
    const { nextGame } = this.props;
    this.setState({ loading: true });
    return this.handleAction(nextGame(this.props.match.params.id));
  }

  timeout = () => {    
    const { timeout } = this.props;
    this.setState({ loading: true });
    return this.handleAction(timeout());
  }  

  handleAction = (action) => {
    return action.then(() => this.setState({
      loading: false,
      error: null,
    })).catch(err => this.setState({
      loading: false,
      error: err.details ? err.details.code : err.code,
    }));
  }

  flushError = () => {
    this.setState({ error: null });
  }

  render = () => {
    const { member, Layout, gameState, history, match, table } = this.props;
    const { error, loading, success } = this.state;

    return (
      <Layout
        error={error}
        member={member}
        loading={loading}
        success={success}
        gameState={gameState}
        history={history}
        leaveTable={this.leaveTable}
        flushError={this.flushError}
        makeMove={this.makeMove}
        tableId={match.params.id}
        table={table}
        nextGame={this.nextGame}
        timeout={this.timeout}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  gameState: state.game.gameState || {},
  table: state.table.table || {}
});

const mapDispatchToProps = dispatch => ({
  leaveTable: dispatch.table.leaveTable,
  fetchGameState: dispatch.game.getGameState,
  makeMove: dispatch.game.makeMove,
  nextGame: dispatch.game.nextGame,
  fetchTable: dispatch.table.getTable,
  timeout: dispatch.game.timeout
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Game);
