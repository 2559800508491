import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Awards extends Component {
  static propTypes = {
    fetchMember: PropTypes.func.isRequired,
    fetchAwards: PropTypes.func.isRequired,
    member: PropTypes.shape().isRequired,
    awards: PropTypes.shape().isRequired
  }

  state = {
    error: null,
    success: null,
    loading: false
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    const { fetchMember, fetchAwards } = this.props;

    this.setState({ loading: true });
    return this.handleAction(Promise.all([
      fetchMember(),
      fetchAwards()
    ]));
  }

  handleAction = (action) => {
    return action.then(() => this.setState({
      loading: false,
      error: null,
    })).catch(err => this.setState({
      loading: false,
      error: err.details ? err.details.code : err.code,
    }));
  }

  flushError = () => {
    this.setState({ error: null });
  }

  render = () => {
    const { Layout, member, awards } = this.props;
    const { error, loading, success } = this.state;

    return (
      <Layout
        member={member}
        awards={awards}
        error={error}
        loading={loading}
        success={success}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  awards: state.awards.awards || {},
});

const mapDispatchToProps = dispatch => ({
  fetchMember: dispatch.member.getMemberData,
  fetchAwards: dispatch.awards.getAwards
});

export default connect(mapStateToProps, mapDispatchToProps)(Awards);