const boardRows = [1, 2, 3, 4, 5, 6, 7, 8];
const boardCols = ["A", "B", "C", "D", "E", "F", "G", "H"];

const DiognalDirections = [
  [1, 1],
  [-1, 1],
  [1, -1],
  [-1, -1]
];

const ELOConstant = 30;

class Checkers {

  static getCells(black) {
    if (black) {
      return {
        nums: boardRows.slice(),
        letters: boardCols.slice().reverse()
      }
    } else {
      return {
        nums: boardRows.slice().reverse(),
        letters: boardCols.slice()
      }
    }
  }

  static kingStatus(playerData, currentPlace, nextPlace) {
    const currentStatus = parseInt(playerData.pieces[currentPlace]);
    if (currentStatus === 2) {
      return 2;
    } else {
      return ((playerData.starter && parseInt(nextPlace[1]) === 8) || (!playerData.starter && parseInt(nextPlace[1]) === 1)) ? 2 : 1;
    }
  }

  static mustKnock(piecePosition, allowedMoves) {
    if (allowedMoves[piecePosition]) {
      const pieceAllowedMoves = allowedMoves[piecePosition];

      for (var n = 0; n < pieceAllowedMoves.length; n++) {
        if (pieceAllowedMoves[n].removes && pieceAllowedMoves[n].removes.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  static movePiece(from, to, myData, opponentData, nextMove) {

    const allowedMoves = Checkers.allowedMoves(myData, opponentData);

    if (nextMove && !Checkers.mustKnock(from, allowedMoves)) {
      throw Error("Turn is ended");
    }

    let moveIndex = 0;
    let validMove = false;

    const pieceAllowedMoves = allowedMoves[from];

    for (var n = 0; n < pieceAllowedMoves.length; n++) {
      if (pieceAllowedMoves[n].moveRange.indexOf(to) > -1) {
        validMove = true;
        moveIndex = n;
        break;
      }
    }

    if (!validMove) {
      throw Error("Not allowed move");
    }

    var regularPieceMoved = parseInt(myData.pieces[from]) === 1 ? true : false;
    var piecesKnocked = false;

    myData.pieces[to] = Checkers.kingStatus(myData, from, to);
    delete myData.pieces[from];

    const removes = pieceAllowedMoves[moveIndex].removes;

    if (removes && removes.length > 0) {
      for (var r = 0; r < removes.length; r++) {
        delete opponentData.pieces[removes[r]];
        piecesKnocked = true;
      }
    }

    return { regularPieceMoved, piecesKnocked };
  }

  static _allowedMovesForPlace(num, letter, step, pieces, opponentPieces) {
    var moveRanges = [];
    var removes = [];
    var mustKnock = false;
    var king = pieces[`${letter}${num}`] === 2 ? true : false;

    for (var n = 0; n < DiognalDirections.length; n++) {
      const diognalDirection = DiognalDirections[n];

      var moveRange = [];
      var removed = [];

      var checkNum = num + diognalDirection[0];
      var checkLetter = boardCols.indexOf(letter) + diognalDirection[1];
      var prevMoveKnock = false;
      var tmpRemoves = [];

      while ((checkNum <= boardRows.length && checkNum > 0) && (checkLetter >= 0 && checkLetter < (boardCols.length))) {
        const checkCellName = `${boardCols[checkLetter]}${checkNum}`;

        if (pieces[checkCellName]) {
          break;
        }
        else if (opponentPieces[checkCellName]) {
          if (!king && prevMoveKnock) {
            break;
          }
          prevMoveKnock = true;
          tmpRemoves.push(checkCellName);
        } else {
          if (!king && (prevMoveKnock || ((step * diognalDirection[0]) > 0))) {
            if (prevMoveKnock) {
              mustKnock = true;
              prevMoveKnock = false;
              removed = removed.concat(tmpRemoves.slice());
              tmpRemoves = [];
            }
            moveRange.push(checkCellName);
          } else if (king) {
            if (prevMoveKnock) {
              mustKnock = true;
              prevMoveKnock = false;
              moveRange = [];
              removed = removed.concat(tmpRemoves.slice());
              tmpRemoves = [];
            }
            moveRange.push(checkCellName);
          }

          if (!king) break;
        }

        checkNum += diognalDirection[0];
        checkLetter += diognalDirection[1];
      }

      if (moveRange.length > 0) {
        moveRanges.push(moveRange);
        removes.push(removed);
      }
    }

    var allowedMoves = [];

    for (n = 0; n < moveRanges.length; n++) {
      if (moveRanges[n].length === 0) {
        continue;
      }

      if (mustKnock && removes[n].length === 0) {
        continue;
      }

      var result = {};
      result["moveRange"] = moveRanges[n];

      if (removes[n].length > 0) {
        result["removes"] = removes[n];
      }

      allowedMoves.push(result);
    }

    return { mustKnock, allowedMoves };
  }

  static allowedMoves(playerData, opponentData) {
    const { starter, pieces } = playerData;
    const { pieces: opponentPieces } = opponentData;

    var allowedMoves = {};
    var allowedKnocks = {};
    var mustKnock = false;

    const step = starter ? 1 : -1;

    Object.keys(pieces).forEach((place) => {
      var num = parseInt(place[1]);
      var letter = place[0];

      var pieceResult = Checkers._allowedMovesForPlace(num, letter, step, pieces, opponentPieces);

      if (pieceResult.allowedMoves.length > 0) {
        if (pieceResult.mustKnock) {
          mustKnock = true;
          allowedKnocks[place] = pieceResult.allowedMoves;
        } else {
          allowedMoves[place] = pieceResult.allowedMoves;
        }
      }
    });

    var result = mustKnock ? allowedKnocks : allowedMoves;
    return result;
  }

  static _probability(a, b) {
    return 1.0 / (1 + Math.pow(10, ((a - b) / 400)));
  }

  static calculateElo(myPoints, opponentPoints, meWins) {
    let opponentP = Checkers._probability(myPoints, opponentPoints);
    let myP = Checkers._probability(opponentPoints, myPoints);

    let result = {};

    if (meWins) {
      result['myChange'] =  Math.round(ELOConstant * (1 - myP));
      result['myCurrent'] = myPoints + result['myChange'];
      result['opponentChange'] = Math.round(ELOConstant * (0 - opponentP));
      result['opponentCurrent'] = opponentPoints + result['opponentChange'];
    } else {
      result['myChange'] = Math.round(ELOConstant * (0 - myP));
      result['myCurrent'] = myPoints + result['myChange'];
      result['opponentChange'] = Math.round(ELOConstant * (1 - opponentP));
      result['opponentCurrent'] = opponentPoints + result['opponentChange'];
    }

    return result;
  }
}

module.exports = Checkers;