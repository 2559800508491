const devMode = (window && window.location) ? (window.location.hostname.includes("localhost") || window.location.hostname.includes("dambrete-test"))  : (process.env.NODE_ENV === 'development');

export default {
  // App Details
  appName: 'Dambrete',

  // Build Configuration - eg. Debug or Release?
  DEV: devMode,

  // Is in APP frame
  isInAppFrame: function(){
    return (window && (
      window.location.hostname.includes('dra.') || 
      window.location.hostname.includes('fb.')
    ));
  },

  isFBApp: function(){
    return (window && (
      window.location.hostname === 'localhost' || // While APP mode only
      window.location.hostname.includes('fb.')
    ));
  },

  isDraApp: function(){
    return (window && (
      window.location.hostname.includes('dra.')
    ));
  },

  // Google Analytics - uses a 'dev' account while we're testing
  gaTrackingId: (devMode) ? 'UA-84284256-2' : 'UA-84284256-1',
};
