import { Firebase, FirebaseRef } from '../lib/firebase';

export default {
  /**
   *  Initial state
   */
  state: {
    gameState: {}
  },

  /**
   * Reducers
   */
  reducers: {
    replaceGameState(state, payload, joinedTable) {
      let gameState = {};
      if (payload && typeof payload === 'object') {
        gameState = payload;
        gameState['joinedTable'] = joinedTable;
      }

      return { ...state, gameState };
    }
  },

  /**
   * Effects/Actions
   */
  effects: () => ({

    _gameStateUpdates(joinedTable) {
      return new Promise((resolve) => {
        if (this.joinedTable) {
          FirebaseRef.child(`gameStates/${this.joinedTable}`).off('value');
        }

        this.joinedTable = joinedTable;

        if (joinedTable) {
          FirebaseRef.child(`gameStates/${joinedTable}`).on('value', (snapshot) => {
            const gameStateData = snapshot.val() || {};
            this.replaceGameState(gameStateData, joinedTable);
            if (resolve) return resolve();
          });
        } else {
          this.replaceGameState({}, null);
          return resolve();
        }
      });
    },

    _memberUpdates(user) {
      return new Promise((resolve) => {
        const ref = FirebaseRef.child(`users/${user.uid}/joinedTable`);
        ref.off('value');
        ref.on('value', (snapshot) => {
          const joinedTable = snapshot.val() || null;
          this._gameStateUpdates(joinedTable).then(() => {
            if (resolve) return resolve();
          });
        });
      });
    },

    resetGameState() {
      this.replaceGameState({}, null);
    },

    getGameState(tableId) {
      if (Firebase === null) return new Promise(resolve => resolve);

      return new Promise((resolve) => {
        Firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            if (tableId) {
              FirebaseRef.child(`users/${user.uid}/joinedTable`).off('value');
              this._gameStateUpdates(tableId).then(() => {
                if (resolve) return resolve();
              });
            } else {
              this._memberUpdates(user).then(() => {
                if (resolve) return resolve();
              });
            }
          } else {
            this.replaceGameState({}, null);
            return new Promise(() => resolve);
          }
        });
      });
    },

    makeMove(params) {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const makeMoveFunction = Firebase.app().functions().httpsCallable('makeMove');

      const { from, movesArr, tableId } = params;

      return new Promise((resolve, reject) => makeMoveFunction({ from, movesArr, tableId }).then(() => {
        return resolve();
      }).catch(reject)).catch((err) => { throw err; });
    },

    nextGame(tableId) {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const nextGameFunction = Firebase.app().functions().httpsCallable('nextGame');

      return new Promise((resolve, reject) => nextGameFunction({ tableId }).then(() => {
        return resolve();
      }).catch(reject)).catch((err) => { throw err; });
    },

    timeout() {
      if (Firebase === null) return () => new Promise(resolve => resolve());

      const timeoutFunction = Firebase.app().functions().httpsCallable('timeout');

      return new Promise((resolve, reject) => timeoutFunction().then(() => {
        return resolve();
      }).catch(reject)).catch((err) => { throw err; });
    }
  })
};
