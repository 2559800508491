import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import Header from '../UI/Header';
import Footer from '../UI/Footer';

const Template = ({ pageTitle, children, fitHeight }) => (
  <Fragment>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <Container>      
      <Header />
      <div className="view-content" style={{minHeight: fitHeight ? 0 : 'auto'}}>
        {children}
      </div>
      <Footer />
    </Container>
  </Fragment>
);


Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
  fitHeight: PropTypes.bool
};

Template.defaultProps = {
  pageTitle: 'React App',
  fitHeight: false
};

export default Template;
