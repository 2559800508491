import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Templates
//import TemplateNothing from '../components/Templates/Nothing';
import TemplateMain from '../components/Templates/Main';

// Routes

import Auth from './Auth';

//import SignUpContainer from '../../containers/SignUp';
//import SignUpComponent from '../components/User/SignUp';

//import LoginContainer from '../../containers/Login';
//import LoginComponent from '../components/User/Login';

//import ForgotPasswordContainer from '../../containers/ForgotPassword';
//import ForgotPasswordComponent from '../components/User/ForgotPassword';

//import UpdateProfileContainer from '../../containers/UpdateProfile';
//import UpdateProfileComponent from '../components/User/UpdateProfile';

import Error from '../components/UI/Error';

import MenuContainer from '../../containers/Menu';
import MenuComponent from '../components/Menu/Menu';

import GameContainer from '../../containers/Game';
import GameComponent from '../components/Game/Game';

import TopContainer from '../../containers/Top';
import TopComponent from '../components/Menu/Top';

import AwardsContainer from '../../containers/Awards';
import AwardsComponent from '../components/Menu/Awards';

import HowToPlay from '../components/Menu/HowToPlay';

const Index = ({ t }) => (
  <Fragment>
    <Auth />
    <Switch>
      <Route
        exact
        path="/"
        render={props => (
          <TemplateMain pageTitle={t('title.home')}>
            <MenuContainer {...props} Layout={MenuComponent} />
          </TemplateMain>
        )}
      />
      <Route
        exact
        path="/how-to-play"
        render={_props => (
          <TemplateMain pageTitle={t('title.howToPlay')}>
            <HowToPlay />
          </TemplateMain>
        )}
      />

      <Route
        exact
        path="/top"
        render={props => (
          <TemplateMain pageTitle={t('title.top')}>
            <TopContainer {...props} Layout={TopComponent} />
          </TemplateMain>
        )}
      />

      <Route
        exact
        path="/awards"
        render={props => (
          <TemplateMain pageTitle={t('title.awards')}>
            <AwardsContainer {...props} Layout={AwardsComponent} />
          </TemplateMain>
        )}
      />
      {/*
      <Route
        path="/sign-up"
        render={props => (
          <TemplateNothing pageTitle="Sign Up">
            <SignUpContainer {...props} Layout={SignUpComponent} />
          </TemplateNothing>
        )}
      />
      <Route
        path="/login"
        render={props => (
          <TemplateNothing pageTitle="Login">
            <LoginContainer {...props} Layout={LoginComponent} />
          </TemplateNothing>
        )}
      />
      <Route
        path="/forgot-password"
        render={props => (
          <TemplateNothing pageTitle="Forgot Password">
            <ForgotPasswordContainer {...props} Layout={ForgotPasswordComponent} />
          </TemplateNothing>
        )}
      />
      <Route
        path="/update-profile"
        render={props => (
          <TemplateMain pageTitle="Update Profile">
            <UpdateProfileContainer {...props} Layout={UpdateProfileComponent} />
          </TemplateMain>
        )}
      />

      <Route
        path="/menu"
        render={props => (
          <TemplateMain pageTitle="Menu">
            <MenuContainer {...props} Layout={MenuComponent} />
          </TemplateMain>
        )}
      />*/}

      <Route
        path="/game/:id"
        render={props => (
          <TemplateMain pageTitle={t('title.game')}>
            <GameContainer {...props} Layout={GameComponent} />
          </TemplateMain>
        )}
      />

      <Route
        render={props => (
          <TemplateMain pageTitle="404 - Page not found">
            <Error {...props} title="404" content="Sorry, the route you requested does not exist" />
          </TemplateMain>
        )}
      />
    </Switch>
  </Fragment>
);

Index.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation('common')(Index);
