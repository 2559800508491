import { Firebase, FirebaseRef } from '../lib/firebase';

export default {
  /**
   *  Initial state
   */
  state: {
    todaysLB: [],
    yesterdaysLB: [],
    alltimeLB: []
  },

  /**
   * Reducers
   */
  reducers: {
    replaceLB(state, { payload, by, slice, stateParam }) {
      let lb = [];

      if (payload && typeof payload === 'object') {
        lb = Object.values(payload);
        lb = lb.filter(o => o[by] > 0);
        lb.sort(function (a, b) {
          return b[by] - a[by];
        });

        if (slice) {
          lb = lb.splice(0, slice);
        }
      }

      let ret = state;
      state[stateParam] = lb;

      return ret;
    }
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    _getLB({ params, tstamp, stateParam }) {
      params = params || {};

      if (Firebase === null) return () => new Promise(resolve => resolve());

      if (params.friends) {
        const UID = (
          FirebaseRef
          && Firebase
          && Firebase.auth()
          && Firebase.auth().currentUser
          && Firebase.auth().currentUser.uid
        ) ? Firebase.auth().currentUser.uid : null;

        if (!UID) return () => new Promise(resolve => resolve());

        return new Promise(async (resolve) => {
          return FirebaseRef.child(`users/${UID}`).on('value', (userSnapshot) => {
            const user = userSnapshot.val();

            if (user && user.friends && user.friends.length > 0) {
              const startAt = user.friends[0];
              const endAt = user.friends[user.friends.length - 1];
              const ref = FirebaseRef.child(`leaderboards/${tstamp}`);
              ref.off('value');

              return ref.orderByKey().startAt(`${startAt}`).endAt(`${endAt}`).on('value', (res) => {
                this.replaceLB({
                  payload: res.val() || {},
                  by: params.by || "mc",
                  slice: params.count || 10,
                  stateParam: stateParam
                });
                return resolve();
              });
            } else {
              this.replaceLB({
                payload: {},
                by: params.by || "mc",
                slice: params.count || 10,
                stateParam: stateParam
              });
              return resolve();
            }
          });
        });
      } else {
        return new Promise(async (resolve) => {
          const ref = FirebaseRef.child(`leaderboards/${tstamp}`);
          ref.off('value');

          return ref.orderByChild(params.by || "mc").startAt(1).limitToLast(params.count || 10).on('value', (res) => {
            this.replaceLB({
              payload: res.val() || {},
              by: params.by || "mc",
              slice: undefined,
              stateParam: stateParam
            });
            return resolve();
          });
        });
      }
    },

    getTodaysLB(params) {
      const dateNow = new Date();
      const tstamp = Date.UTC(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDay());

      return this._getLB({ params, tstamp, stateParam: 'todaysLB' });
    },

    getYesterdaysLB(params) {
      const dateNow = new Date();
      dateNow.setDate(dateNow.getDate() - 1);
      const tstamp = Date.UTC(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDay());

      return this._getLB({ params, tstamp, stateParam: 'yesterdaysLB' });
    },

    getAlltimeLB(params) {
      return this._getLB({ params, tstamp: 'alltime', stateParam: 'alltimeLB' });
    }
  })
};
